.m-0,
.mx-0,
.mr-0 {
  margin-right: 0rem !important;
}

.m-0,
.mx-0,
.ml-0 {
  margin-left: 0rem !important;
}

.m-0,
.my-0,
.mt-0 {
  margin-top: 0rem !important;
}

.m-0,
.my-0,
.mb-0 {
  margin-bottom: 0rem !important;
}

.p-0,
.px-0,
.pr-0 {
  padding-right: 0rem !important;
}

.p-0,
.px-0,
.pl-0 {
  padding-left: 0rem !important;
}

.p-0,
.py-0,
.pt-0 {
  padding-top: 0rem !important;
}

.p-0,
.py-0,
.pb-0 {
  padding-bottom: 0rem !important;
}

.m-1,
.mx-1,
.mr-1 {
  margin-right: 0.25rem !important;
}

.m-1,
.mx-1,
.ml-1 {
  margin-left: 0.25rem !important;
}

.m-1,
.my-1,
.mt-1 {
  margin-top: 0.25rem !important;
}

.m-1,
.my-1,
.mb-1 {
  margin-bottom: 0.25rem !important;
}

.p-1,
.px-1,
.pr-1 {
  padding-right: 0.25rem !important;
}

.p-1,
.px-1,
.pl-1 {
  padding-left: 0.25rem !important;
}

.p-1,
.py-1,
.pt-1 {
  padding-top: 0.25rem !important;
}

.p-1,
.py-1,
.pb-1 {
  padding-bottom: 0.25rem !important;
}

.m-2,
.mx-2,
.mr-2 {
  margin-right: 0.5rem !important;
}

.m-2,
.mx-2,
.ml-2 {
  margin-left: 0.5rem !important;
}

.m-2,
.my-2,
.mt-2 {
  margin-top: 0.5rem !important;
}

.m-2,
.my-2,
.mb-2 {
  margin-bottom: 0.5rem !important;
}

.p-2,
.px-2,
.pr-2 {
  padding-right: 0.5rem !important;
}

.p-2,
.px-2,
.pl-2 {
  padding-left: 0.5rem !important;
}

.p-2,
.py-2,
.pt-2 {
  padding-top: 0.5rem !important;
}

.p-2,
.py-2,
.pb-2 {
  padding-bottom: 0.5rem !important;
}

.m-3,
.mx-3,
.mr-3 {
  margin-right: 0.75rem !important;
}

.m-3,
.mx-3,
.ml-3 {
  margin-left: 0.75rem !important;
}

.m-3,
.my-3,
.mt-3 {
  margin-top: 0.75rem !important;
}

.m-3,
.my-3,
.mb-3 {
  margin-bottom: 0.75rem !important;
}

.p-3,
.px-3,
.pr-3 {
  padding-right: 0.75rem !important;
}

.p-3,
.px-3,
.pl-3 {
  padding-left: 0.75rem !important;
}

.p-3,
.py-3,
.pt-3 {
  padding-top: 0.75rem !important;
}

.p-3,
.py-3,
.pb-3 {
  padding-bottom: 0.75rem !important;
}

.m-4,
.mx-4,
.mr-4 {
  margin-right: 1rem !important;
}

.m-4,
.mx-4,
.ml-4 {
  margin-left: 1rem !important;
}

.m-4,
.my-4,
.mt-4 {
  margin-top: 1rem !important;
}

.m-4,
.my-4,
.mb-4 {
  margin-bottom: 1rem !important;
}

.p-4,
.px-4,
.pr-4 {
  padding-right: 1rem !important;
}

.p-4,
.px-4,
.pl-4 {
  padding-left: 1rem !important;
}

.p-4,
.py-4,
.pt-4 {
  padding-top: 1rem !important;
}

.p-4,
.py-4,
.pb-4 {
  padding-bottom: 1rem !important;
}

.m-5,
.mx-5,
.mr-5 {
  margin-right: 1.25rem !important;
}

.m-5,
.mx-5,
.ml-5 {
  margin-left: 1.25rem !important;
}

.m-5,
.my-5,
.mt-5 {
  margin-top: 1.25rem !important;
}

.m-5,
.my-5,
.mb-5 {
  margin-bottom: 1.25rem !important;
}

.p-5,
.px-5,
.pr-5 {
  padding-right: 1.25rem !important;
}

.p-5,
.px-5,
.pl-5 {
  padding-left: 1.25rem !important;
}

.p-5,
.py-5,
.pt-5 {
  padding-top: 1.25rem !important;
}

.p-5,
.py-5,
.pb-5 {
  padding-bottom: 1.25rem !important;
}

.m-6,
.mx-6,
.mr-6 {
  margin-right: 1.5rem !important;
}

.m-6,
.mx-6,
.ml-6 {
  margin-left: 1.5rem !important;
}

.m-6,
.my-6,
.mt-6 {
  margin-top: 1.5rem !important;
}

.m-6,
.my-6,
.mb-6 {
  margin-bottom: 1.5rem !important;
}

.p-6,
.px-6,
.pr-6 {
  padding-right: 1.5rem !important;
}

.p-6,
.px-6,
.pl-6 {
  padding-left: 1.5rem !important;
}

.p-6,
.py-6,
.pt-6 {
  padding-top: 1.5rem !important;
}

.p-6,
.py-6,
.pb-6 {
  padding-bottom: 1.5rem !important;
}

.m-7,
.mx-7,
.mr-7 {
  margin-right: 1.75rem !important;
}

.m-7,
.mx-7,
.ml-7 {
  margin-left: 1.75rem !important;
}

.m-7,
.my-7,
.mt-7 {
  margin-top: 1.75rem !important;
}

.m-7,
.my-7,
.mb-7 {
  margin-bottom: 1.75rem !important;
}

.p-7,
.px-7,
.pr-7 {
  padding-right: 1.75rem !important;
}

.p-7,
.px-7,
.pl-7 {
  padding-left: 1.75rem !important;
}

.p-7,
.py-7,
.pt-7 {
  padding-top: 1.75rem !important;
}

.p-7,
.py-7,
.pb-7 {
  padding-bottom: 1.75rem !important;
}

.m-8,
.mx-8,
.mr-8 {
  margin-right: 2rem !important;
}

.m-8,
.mx-8,
.ml-8 {
  margin-left: 2rem !important;
}

.m-8,
.my-8,
.mt-8 {
  margin-top: 2rem !important;
}

.m-8,
.my-8,
.mb-8 {
  margin-bottom: 2rem !important;
}

.p-8,
.px-8,
.pr-8 {
  padding-right: 2rem !important;
}

.p-8,
.px-8,
.pl-8 {
  padding-left: 2rem !important;
}

.p-8,
.py-8,
.pt-8 {
  padding-top: 2rem !important;
}

.p-8,
.py-8,
.pb-8 {
  padding-bottom: 2rem !important;
}

.m-9,
.mx-9,
.mr-9 {
  margin-right: 2.25rem !important;
}

.m-9,
.mx-9,
.ml-9 {
  margin-left: 2.25rem !important;
}

.m-9,
.my-9,
.mt-9 {
  margin-top: 2.25rem !important;
}

.m-9,
.my-9,
.mb-9 {
  margin-bottom: 2.25rem !important;
}

.p-9,
.px-9,
.pr-9 {
  padding-right: 2.25rem !important;
}

.p-9,
.px-9,
.pl-9 {
  padding-left: 2.25rem !important;
}

.p-9,
.py-9,
.pt-9 {
  padding-top: 2.25rem !important;
}

.p-9,
.py-9,
.pb-9 {
  padding-bottom: 2.25rem !important;
}

.m-10,
.mx-10,
.mr-10 {
  margin-right: 2.5rem !important;
}

.m-10,
.mx-10,
.ml-10 {
  margin-left: 2.5rem !important;
}

.m-10,
.my-10,
.mt-10 {
  margin-top: 2.5rem !important;
}

.m-10,
.my-10,
.mb-10 {
  margin-bottom: 2.5rem !important;
}

.p-10,
.px-10,
.pr-10 {
  padding-right: 2.5rem !important;
}

.p-10,
.px-10,
.pl-10 {
  padding-left: 2.5rem !important;
}

.p-10,
.py-10,
.pt-10 {
  padding-top: 2.5rem !important;
}

.p-10,
.py-10,
.pb-10 {
  padding-bottom: 2.5rem !important;
}

.m-11,
.mx-11,
.mr-11 {
  margin-right: 2.75rem !important;
}

.m-11,
.mx-11,
.ml-11 {
  margin-left: 2.75rem !important;
}

.m-11,
.my-11,
.mt-11 {
  margin-top: 2.75rem !important;
}

.m-11,
.my-11,
.mb-11 {
  margin-bottom: 2.75rem !important;
}

.p-11,
.px-11,
.pr-11 {
  padding-right: 2.75rem !important;
}

.p-11,
.px-11,
.pl-11 {
  padding-left: 2.75rem !important;
}

.p-11,
.py-11,
.pt-11 {
  padding-top: 2.75rem !important;
}

.p-11,
.py-11,
.pb-11 {
  padding-bottom: 2.75rem !important;
}

.m-12,
.mx-12,
.mr-12 {
  margin-right: 3rem !important;
}

.m-12,
.mx-12,
.ml-12 {
  margin-left: 3rem !important;
}

.m-12,
.my-12,
.mt-12 {
  margin-top: 3rem !important;
}

.m-12,
.my-12,
.mb-12 {
  margin-bottom: 3rem !important;
}

.p-12,
.px-12,
.pr-12 {
  padding-right: 3rem !important;
}

.p-12,
.px-12,
.pl-12 {
  padding-left: 3rem !important;
}

.p-12,
.py-12,
.pt-12 {
  padding-top: 3rem !important;
}

.p-12,
.py-12,
.pb-12 {
  padding-bottom: 3rem !important;
}

.m-13,
.mx-13,
.mr-13 {
  margin-right: 3.25rem !important;
}

.m-13,
.mx-13,
.ml-13 {
  margin-left: 3.25rem !important;
}

.m-13,
.my-13,
.mt-13 {
  margin-top: 3.25rem !important;
}

.m-13,
.my-13,
.mb-13 {
  margin-bottom: 3.25rem !important;
}

.p-13,
.px-13,
.pr-13 {
  padding-right: 3.25rem !important;
}

.p-13,
.px-13,
.pl-13 {
  padding-left: 3.25rem !important;
}

.p-13,
.py-13,
.pt-13 {
  padding-top: 3.25rem !important;
}

.p-13,
.py-13,
.pb-13 {
  padding-bottom: 3.25rem !important;
}

.m-14,
.mx-14,
.mr-14 {
  margin-right: 3.5rem !important;
}

.m-14,
.mx-14,
.ml-14 {
  margin-left: 3.5rem !important;
}

.m-14,
.my-14,
.mt-14 {
  margin-top: 3.5rem !important;
}

.m-14,
.my-14,
.mb-14 {
  margin-bottom: 3.5rem !important;
}

.p-14,
.px-14,
.pr-14 {
  padding-right: 3.5rem !important;
}

.p-14,
.px-14,
.pl-14 {
  padding-left: 3.5rem !important;
}

.p-14,
.py-14,
.pt-14 {
  padding-top: 3.5rem !important;
}

.p-14,
.py-14,
.pb-14 {
  padding-bottom: 3.5rem !important;
}

.m-15,
.mx-15,
.mr-15 {
  margin-right: 3.75rem !important;
}

.m-15,
.mx-15,
.ml-15 {
  margin-left: 3.75rem !important;
}

.m-15,
.my-15,
.mt-15 {
  margin-top: 3.75rem !important;
}

.m-15,
.my-15,
.mb-15 {
  margin-bottom: 3.75rem !important;
}

.p-15,
.px-15,
.pr-15 {
  padding-right: 3.75rem !important;
}

.p-15,
.px-15,
.pl-15 {
  padding-left: 3.75rem !important;
}

.p-15,
.py-15,
.pt-15 {
  padding-top: 3.75rem !important;
}

.p-15,
.py-15,
.pb-15 {
  padding-bottom: 3.75rem !important;
}

.m-16,
.mx-16,
.mr-16 {
  margin-right: 4rem !important;
}

.m-16,
.mx-16,
.ml-16 {
  margin-left: 4rem !important;
}

.m-16,
.my-16,
.mt-16 {
  margin-top: 4rem !important;
}

.m-16,
.my-16,
.mb-16 {
  margin-bottom: 4rem !important;
}

.p-16,
.px-16,
.pr-16 {
  padding-right: 4rem !important;
}

.p-16,
.px-16,
.pl-16 {
  padding-left: 4rem !important;
}

.p-16,
.py-16,
.pt-16 {
  padding-top: 4rem !important;
}

.p-16,
.py-16,
.pb-16 {
  padding-bottom: 4rem !important;
}

.m-17,
.mx-17,
.mr-17 {
  margin-right: 4.25rem !important;
}

.m-17,
.mx-17,
.ml-17 {
  margin-left: 4.25rem !important;
}

.m-17,
.my-17,
.mt-17 {
  margin-top: 4.25rem !important;
}

.m-17,
.my-17,
.mb-17 {
  margin-bottom: 4.25rem !important;
}

.p-17,
.px-17,
.pr-17 {
  padding-right: 4.25rem !important;
}

.p-17,
.px-17,
.pl-17 {
  padding-left: 4.25rem !important;
}

.p-17,
.py-17,
.pt-17 {
  padding-top: 4.25rem !important;
}

.p-17,
.py-17,
.pb-17 {
  padding-bottom: 4.25rem !important;
}

.m-18,
.mx-18,
.mr-18 {
  margin-right: 4.5rem !important;
}

.m-18,
.mx-18,
.ml-18 {
  margin-left: 4.5rem !important;
}

.m-18,
.my-18,
.mt-18 {
  margin-top: 4.5rem !important;
}

.m-18,
.my-18,
.mb-18 {
  margin-bottom: 4.5rem !important;
}

.p-18,
.px-18,
.pr-18 {
  padding-right: 4.5rem !important;
}

.p-18,
.px-18,
.pl-18 {
  padding-left: 4.5rem !important;
}

.p-18,
.py-18,
.pt-18 {
  padding-top: 4.5rem !important;
}

.p-18,
.py-18,
.pb-18 {
  padding-bottom: 4.5rem !important;
}

.m-19,
.mx-19,
.mr-19 {
  margin-right: 4.75rem !important;
}

.m-19,
.mx-19,
.ml-19 {
  margin-left: 4.75rem !important;
}

.m-19,
.my-19,
.mt-19 {
  margin-top: 4.75rem !important;
}

.m-19,
.my-19,
.mb-19 {
  margin-bottom: 4.75rem !important;
}

.p-19,
.px-19,
.pr-19 {
  padding-right: 4.75rem !important;
}

.p-19,
.px-19,
.pl-19 {
  padding-left: 4.75rem !important;
}

.p-19,
.py-19,
.pt-19 {
  padding-top: 4.75rem !important;
}

.p-19,
.py-19,
.pb-19 {
  padding-bottom: 4.75rem !important;
}

.m-20,
.mx-20,
.mr-20 {
  margin-right: 5rem !important;
}

.m-20,
.mx-20,
.ml-20 {
  margin-left: 5rem !important;
}

.m-20,
.my-20,
.mt-20 {
  margin-top: 5rem !important;
}

.m-20,
.my-20,
.mb-20 {
  margin-bottom: 5rem !important;
}

.p-20,
.px-20,
.pr-20 {
  padding-right: 5rem !important;
}

.p-20,
.px-20,
.pl-20 {
  padding-left: 5rem !important;
}

.p-20,
.py-20,
.pt-20 {
  padding-top: 5rem !important;
}

.p-20,
.py-20,
.pb-20 {
  padding-bottom: 5rem !important;
}

.m-auto,
.mx-auto,
.mr-auto {
  margin-right: auto !important;
}

.m-auto,
.mx-auto,
.ml-auto {
  margin-left: auto !important;
}

.m-auto,
.my-auto,
.mt-auto {
  margin-top: auto !important;
}

.m-auto,
.my-auto,
.mb-auto {
  margin-bottom: auto !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.bg-primary {
  background-color: var(--primary);
  color: var(--white);
}

.bg-green {
  background-color: var(--green);
  color: var(--white);
}

.bg-orange {
  background-color: var(--orange);
  color: var(--white);
}

.bg-secondary {
  background-color: var(--secondary);
  color: var(--white);
}

.bg-gray {
  background-color: var(--gray);
  color: var(--primary);
}

.bg-blue {
  background-color: var(--blue);
  color: var(--white);
}

.bg-white {
  background-color: var(--white);
  color: var(--black);
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-disabled {
  background-color: rgba(23, 25, 28, 0.1) !important;
}
.text-white {
  color: var(--white);
}

.text-black {
  color: var(--black);
}

.text-primary {
  color: var(--primary);
}

.text-gray-black {
  color: var(--gray-black);
}

.text-secondary {
  color: var(--secondary);
}

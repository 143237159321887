.input-search .MuiInput-formControl::before,
.input-search .MuiInput-formControl::after,
.input-container .MuiInput-formControl::before,
.input-container .MuiInput-formControl::after {
  display: none;
}

.input-search .MuiInput-formControl {
  border: 1px solid var(--gray) !important;
  background-color: var(--white);
  border-radius: 0.25rem;
  padding: 0px 5px;
}

.input-container {
  transition: all linear 0.2s;
}

.input-container label {
  color: var(--black) !important;
  transform: unset;
  position: unset;
  font-size: 0.9rem;
  font-weight: 500;
}

.input-container .MuiInput-formControl {
  background: var(--white);
  border: 2px solid var(--gray);
  border-radius: 10px;
  height: auto;
}

.input-container:has(label) .MuiInput-formControl {
  margin-top: 0.5rem;
}

.input-container .MuiInput-formControl:has(:is(.Mui-disabled, input:disabled)) {
  background-color: rgba(23, 25, 28, 0.1);
}

.input-container
  .MuiInput-formControl:has(:is(.Mui-disabled, input:disabled))
  input {
  max-width: 100%;
}

.input-container :is(input, textarea) {
  font-size: 0.9rem;
  font-weight: 400;
  padding: 0.75rem !important;
  resize: both;
  color: var(--black);
}

.input-container .MuiFormLabel-asterisk.MuiInputLabel-asterisk {
  color: var(--secondary);
}

.input-container .MuiInputBase-root.MuiAutocomplete-inputRoot {
  padding-right: 0 !important;
}
.input-container .MuiInput-formControl .MuiAutocomplete-endAdornment {
  position: static;
}

input[type='checkbox'] {
  width: 20px;
  height: 20px;
}

.input-select-no_fly_zone_method
  :is(.MuiChip-deleteIcon, .MuiAutocomplete-endAdornment) {
  display: none;
}

.input-select-no_fly_zone_method input {
  max-width: 0px !important;
  padding-left: 0.01px !important;
  padding-right: 0px !important;
  min-width: unset !important;
}

.input-select-no_fly_zone_method .MuiAutocomplete-inputRoot.Mui-disabled {
  background-color: var(--white) !important;
}

.input-select-no_fly_zone_method .MuiChip-root.Mui-disabled {
  opacity: 1;
}

.input-select-no_fly_zone_method .MuiInputBase-root.Mui-disabled {
  cursor: pointer;
}

.input-container .MuiInputBase-multiline {
  padding: 0;
}

.rounded-1 {
  border-radius: 0.25rem;
}

.rounded-2 {
  border-radius: 0.5rem !important;
}

.rounded-full {
  border-radius: 100%;
}

.outline-none {
  outline: none !important;
}

.border-gray {
  border: 1px solid var(--gray);
}

.border-none {
  border: none !important;
}

.w-full {
  width: 100% !important;
}

.h-full {
  height: 100%;
}

.h-auto {
  height: auto !important;
}

.min-w-max {
  min-width: max-content !important;
}

.min-w-none {
  min-width: unset !important;
}

.h-max {
  height: max-content;
}

.min-h-full {
  min-height: 100% !important;
}

.w-max {
  width: max-content;
}

.max-w-full {
  max-width: 100%;
}

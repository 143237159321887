:is(button, label).MuiButton-root {
  border-radius: 0.5rem;
  font-size: 0.9rem;
  height: 40px;
  min-width: unset;
}

.btn-white {
  color: var(--primary) !important;
  background-color: var(--white) !important;
  border: 1px solid var(--gray) !important;
}

.btn-primary {
  color: var(--white) !important;
  background-color: var(--primary) !important;
}

.btn-gray-black {
  background-color: var(--gray-black) !important;
  color: var(--white) !important;
}

.btn-blue {
  background-color: var(--blue) !important;
  color: var(--white) !important;
}

.btn-orange-2 {
  background-color: var(--orange-2) !important;
  color: var(--white) !important;
}

.btn-success {
  background-color: #49bd65 !important;
  color: var(--white) !important;
}

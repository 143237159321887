.table-container .MuiTableCell-head {
  min-width: max-content;
  font-size: 0.9rem;
  background-color: #213983;
  color: var(--white);
  font-weight: 600;
}

.table-container .MuiTableCell-body {
  font-size: 0.9rem !important;
}

.table-container .MuiTableBody-root .MuiTableRow-root:nth-child(even) {
  background-color: #f8f9fa;
}

.table-output_log {
  border: 1px solid #dddddd;
}

.table-output_log table {
  table-layout: auto;
  width: 100%;
  border-collapse: collapse;
}

.table-output_log table *:is(th, td) {
  border: 1px solid #dddddd;
  padding: 0.5rem 1rem;
  text-align: center;
}

.table-output_log table th {
  font-weight: 600;
}

.table-output_log::-webkit-scrollbar,
.table-output_log::-webkit-scrollbar-thumb {
  height: 9px;
  width: 9px;
  border: 2px solid transparent;
  background-color: var(--white);
}

.table-output_log::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 9px;
  color: #aaa;
  border-radius: 9px;
}

.table-container :is(.MuiTableCell-body, .MuiTableCell-head),
.table-output_log :is(th, td) {
  white-space: nowrap;
}

.table-form :is(.MuiTableCell-body, .MuiTableCell-head) {
  border: 1.5px solid var(--primary);
}

.table-form .MuiTableCell-body {
  padding: 0;
  background-color: var(--white);
}

.table-form .input-container .MuiInput-formControl {
  border-radius: 0;
  border: unset;
}

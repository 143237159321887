.flex {
  display: flex !important;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.d-block {
  display: block !important;
}

.d-inline-block {
  display: inline-block !important;
}
.d-none {
  display: none !important;
}
.d-inline-flex {
  display: inline-flex !important;
}

.items-center {
  align-items: center !important;
}

.items-start {
  align-items: flex-start !important;
}

.items-end {
  align-items: flex-end !important;
}

.justify-center {
  justify-content: center !important;
}

.justify-start {
  justify-content: flex-start !important;
}

.justify-end {
  justify-content: flex-end !important;
}

.justify-between {
  justify-content: space-between !important;
}

.justify-around {
  justify-content: space-around !important;
}

.justify-evenly {
  justify-content: space-evenly !important;
}

.direction-column {
  flex-direction: column;
}

@media (min-width: 600px) {
  .d-sm-none {
    display: none !important;
  }
}

@media (min-width: 1280px) {
  .d-lg-block {
    display: block !important;
  }
}

:root {
  --light: 300;
  --regular: 400;
  --medium: 500;
  --bold: 600;
  --extra-bold: 700;
  font-family: 'Poppins', serif;
}

body {
  line-height: 1.35 !important;
  font-family: 'Poppins', serif;
}

* {
  margin: 0;
  padding: 0;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
}

h1 {
  font-size: 22px;
}

h2 {
  font-size: 20px;
}

h3 {
  font-size: 18px;
}

h4 {
  font-size: 16px;
}

h5 {
  font-size: 14px;
}

h6 {
  font-size: 14px;
}

/* Calendar Module Style */
.app-calendar,
.rbc-calendar {
  font-family: 'Poppins', serif;
  width: 100%;
}

.app-calendar .rbc-calendar {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex: 1 1 auto;
  width: 100%;
  min-height: calc(100vh - 360px);
}

.app-cul-calendar .rbc-calendar {
  min-height: calc(100vh - 400px);
}

.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active {
  background-image: none;
  -o-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.rbc-month-row {
  min-height: 100px;
}

.rbc-event {
  min-height: 50px;
}

.rbc-event:focus {
  outline: none;
}

.cr-calendar .react-daypicker-root {
  border: solid 1px #e5e9ec;
  border-radius: 30px;
  padding: 30px;
  width: 100%;
  height: 100%;
}

.cr-calendar table {
  width: 100%;
  height: calc(100% - 30px);
}

.cr-calendar table td {
  position: relative;
}

.cr-calendar table th {
  text-transform: uppercase;
  font-weight: var(--light);
  font-size: 15px;
}

.cr-calendar .header .month-year {
  font-weight: var(--extra-bold);
  padding-top: 3px;
}

.cr-calendar-color .react-daypicker-root {
  border: 0 none;
  font-size: 20px;
}

.cr-calendar-color .header {
  padding: 30px;
  margin: -30px -30px 0;
}

.cr-calendar-color .header .month-year {
  font-size: 22px;
}

.cr-calendar-color table {
  font-weight: var(--extra-bold);
  height: calc(100% - 60px);
}

.cr-calendar-color table thead th {
  font-weight: var(--extra-bold);
  font-size: 20px;
}

@media screen and (max-width: 1919px) {
  .cr-calendar .react-daypicker-root {
    padding: 20px;
  }

  .cr-calendar .react-daypicker-root th,
  .cr-calendar .react-daypicker-root td {
    width: 30px;
  }

  .cr-calendar table th,
  .cr-calendar table td {
    font-size: 12px;
  }

  .cr-calendar-color .react-daypicker-root {
    font-size: 16px;
  }

  .cr-calendar-color .header {
    padding: 20px;
    margin: -20px -20px 0;
  }

  .cr-calendar-color .header .month-year {
    font-size: 18px;
  }

  .cr-calendar-color table thead th {
    font-size: 15px;
  }

  .cr-calendar-color table {
    height: calc(100% - 70px);
  }
}

@media screen and (max-width: 1367px) {
  .cr-calendar-color table thead th {
    font-size: 13px;
  }
}

@media screen and (max-width: 959px) {
  .rbc-toolbar {
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
  }

  .rbc-toolbar .rbc-toolbar-label {
    margin: 8px 0;
  }

  .cr-calendar table {
    height: auto;
  }
}

@media screen and (max-width: 599px) {
  .cr-calendar .react-daypicker-root {
    padding: 10px;
  }

  .cr-calendar .react-daypicker-root th,
  .cr-calendar .react-daypicker-root td {
    width: 20px;
  }

  .app-calendar .rbc-calendar {
    min-height: 400px;
  }

  .app-cul-calendar .rbc-calendar {
    min-height: 400px;
  }
}

@media screen and (max-width: 467px) {
  .rbc-toolbar {
    font-size: 12px;
  }

  .rbc-toolbar button {
    padding: 5px 10px;
  }

  .cr-calendar table th,
  .cr-calendar table td {
    font-size: 12px;
  }
}

.notification-custom-icon {
  flex-basis: 20%;
  position: relative;
  padding: 8px 8px 8px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notification-custom-icon .fa {
  color: #fff;
  font-size: 28px;
}

.notification-custom-content {
  flex-basis: 80%;
  padding: 12px 12px 12px 8px;
  display: inline-block;
}

.notification-custom-success {
  width: 100%;
  display: flex;
  background-color: #28a745;
}

.notification-custom-success .notification-custom-icon {
  border-left: 8px solid #19692c;
}

.notification-custom-default {
  width: 100%;
  display: flex;
  background-color: #007bff;
}

.notification-custom-default .notification-custom-icon {
  border-left: 8px solid #0056b3;
}

.notification-custom-danger {
  width: 100%;
  display: flex;
  background-color: #dc3545;
}

.notification-custom-danger .notification-custom-icon {
  border-left: 8px solid #a71d2a;
}

.notification-custom-info {
  width: 100%;
  display: flex;
  background-color: #17a2b8;
}

.notification-custom-info .notification-custom-icon {
  border-left: 8px solid #0f6674;
}

.notification-custom-warning {
  width: 100%;
  display: flex;
  background-color: #eab000;
}

.notification-custom-warning .notification-custom-icon {
  border-left: 8px solid #9e7600;
}

.custom-image-content {
  background-color: white;
  padding: 10px;
}

.ReactTable {
  width: 100%;
}

.ReactTable .rt-th,
.ReactTable .rt-td {
  padding: 12px 15px;
}

.ReactTable .rt-th.rt-expandable,
.ReactTable .rt-td.rt-expandable {
  padding: 0;
}

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
  padding: 12px 15px;
}

.ReactTable .rt-thead .rt-th.rt-expandable,
.ReactTable .rt-thead .rt-td.rt-expandable {
  padding: 0;
}

.ReactTable .rt-td > div {
  padding: 3px 10px;
}

@media screen and (max-width: 399px) {
  .ReactTable .-pagination {
    flex-direction: column;
  }
}

.document-editor {
  border: 1px solid var(--ck-color-base-border);
  border-radius: var(--ck-border-radius);
  /* Set vertical boundaries for the document editor. */
  max-height: 700px;
  font-family: 'Poppins', serif;
  /* This element is a flex container for easier rendering. */
  display: flex;
  flex-flow: column nowrap;
}

.document-editor__toolbar {
  /* Make sure the toolbar container is always above the editable. */
  z-index: 1;
  /* Create the illusion of the toolbar floating over the editable. */
  box-shadow: 0 0 5px hsla(0, 0%, 0%, 0.2);
  /* Use the CKEditor CSS variables to keep the UI consistent. */
  border-bottom: 1px solid var(--ck-color-toolbar-border);
}

/* Adjust the look of the toolbar inside of the container. */
.document-editor__toolbar .ck-toolbar {
  border: 0;
  border-radius: 0;
}

/* Make the editable container look like the inside of a native word processor app. */
.document-editor__editable-container {
  padding: calc(2 * var(--ck-spacing-large));
  background: var(--ck-color-base-foreground);
  /* Make it possible to scroll the "page" of the edited content. */
  overflow-y: scroll;
}

.document-editor__editable-container
  .document-editor__editable.ck-editor__editable {
  /* Set the dimensions of the "page". */
  width: 15.8cm;
  min-height: 21cm;
  /* Keep the "page" off the boundaries of the container. */
  padding: 1cm 2cm 2cm;
  border: 1px hsl(0, 0%, 82.7%) solid;
  border-radius: var(--ck-border-radius);
  background: white;
  /* The "page" should cast a slight shadow (3D illusion). */
  box-shadow: 0 0 5px hsla(0, 0%, 0%, 0.1);
  /* Center the "page". */
  margin: 0 auto;
}

/* Override the page's width in the "Examples" section which is wider. */
.main__content-wide
  .document-editor__editable-container
  .document-editor__editable.ck-editor__editable {
  width: 18cm;
}

/* Set the default font for the "page" of the content. */
.document-editor .ck-content,
.document-editor .ck-heading-dropdown .ck-list .ck-button__label {
  font: 16px/1.6 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

/* Adjust the headings dropdown to host some larger heading styles. */
.document-editor .ck-heading-dropdown .ck-list .ck-button__label {
  line-height: calc(
    1.7 * var(--ck-line-height-base) * var(--ck-font-size-base)
  );
  min-width: 6em;
}

/* Scale down all heading previews because they are way too big to be presented in the UI.
Preserve the relative scale, though. */
.document-editor
  .ck-heading-dropdown
  .ck-list
  .ck-heading_heading1
  .ck-button__label,
.document-editor
  .ck-heading-dropdown
  .ck-list
  .ck-heading_heading2
  .ck-button__label {
  transform: scale(0.8);
  transform-origin: left;
}

/* Set the styles for "Heading 1". */
.document-editor .ck-content h2,
.document-editor .ck-heading-dropdown .ck-heading_heading1 .ck-button__label {
  font-size: 2.18em;
  font-weight: 400;
}

.document-editor .ck-content h2 {
  line-height: 1.37em;
  padding-top: 0.342em;
  margin-bottom: 0.142em;
}

/* Set the styles for "Heading 2". */
.document-editor .ck-content h3,
.document-editor .ck-heading-dropdown .ck-heading_heading2 .ck-button__label {
  font-size: 1.75em;
  font-weight: 400;
  color: hsl(203, 100%, 50%);
}

.document-editor
  .ck-heading-dropdown
  .ck-heading_heading2.ck-on
  .ck-button__label {
  color: var(--ck-color-list-button-on-text);
}

/* Set the styles for "Heading 2". */
.document-editor .ck-content h3 {
  line-height: 1.86em;
  padding-top: 0.171em;
  margin-bottom: 0.357em;
}

/* Set the styles for "Heading 3". */
.document-editor .ck-content h4,
.document-editor .ck-heading-dropdown .ck-heading_heading3 .ck-button__label {
  font-size: 1.31em;
  font-weight: 600;
}

.document-editor .ck-content h4 {
  line-height: 1.24em;
  padding-top: 0.286em;
  margin-bottom: 0.952em;
}

/* Make the block quoted text serif with some additional spacing. */
.document-editor .ck-content blockquote {
  font-weight: var(--regular) !important;
  margin-left: calc(2 * var(--ck-spacing-large));
  margin-right: calc(2 * var(--ck-spacing-large));
}

/* Some table cells have a lot content and some not. Align them vertically
to make reading easier. */
.document-editor .ck-content table td {
  vertical-align: middle;
}

/*Embed Responsive Classes*/
.cr-embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.cr-embed-responsive:before {
  display: block;
  content: '';
}

.cr-embed-responsive .cr-embed-responsive-item,
.cr-embed-responsive iframe,
.cr-embed-responsive embed,
.cr-embed-responsive object,
.cr-embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.cr-embed-responsive-21by9:before {
  padding-top: 42.85714286%;
}

.cr-embed-responsive-16by9:before {
  padding-top: 56.25%;
}

.cr-embed-responsive-4by3:before {
  padding-top: 75%;
}

.cr-embed-responsive-1by1:before {
  padding-top: 100%;
}

@media screen and (max-width: 499px) {
  .cr-embed-responsive-21by9:before,
  .cr-embed-responsive-16by9:before {
    padding-top: 100%;
  }
}

.rt-timeline-container {
  padding: 1em;
  font-family: 'Poppins', serif !important;
  justify-content: center;
  align-items: center;
  display: flex;
}

.rt-timeline {
  max-width: 1600px;
  width: 100%;
  padding: 0;
  list-style-type: none;
  position: relative;
}

.rt-timeline:after {
  left: calc(50% - 0.5px);
  top: 1px;
  z-index: 1;
  width: 1px;
  height: 100%;
  content: '';
  position: absolute;
  background-color: #d5d6d8;
}

.rt-label-container {
  clear: both;
  margin: 1em auto 1em auto;
  display: flex;
  justify-content: center;
}

.rt-label-container:first-of-type {
  margin-top: 0 !important;
}

.rt-label-container:last-of-type {
  margin-bottom: 0;
}

.rt-label {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 50%;
  font-size: 24px;
  font-weight: var(--medium) !important;
  width: 3em;
  height: 3em;
  position: relative;
  z-index: 3;
}

.rt-clear {
  clear: both;
}

.rt-backing {
  padding: 20px;
}

.rt-event {
  position: relative;
  float: right;
  clear: right;
  width: 50%;
  margin-top: 2em;
  margin-left: 0;
  margin-right: 0;
  padding-left: 65px;
  list-style-type: none;
  display: block;
  min-height: 700px;
}

.rt-event .rt-dot {
  position: absolute;
  margin-top: 20px;
  left: -101px;
  right: auto;
  fill: currentcolor;
  font-size: 14px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  z-index: 100;
}

.rt-event .rt-svg-container {
  position: relative;
}

.rt-event .rt-svg-container svg {
  transform: scale(-1, 1);
}

.rt-event .rt-svg-container .rt-arrow {
  z-index: 100;
  position: absolute;
  margin-top: 20px;
  left: -42px;
  right: auto;
  fill: currentcolor;
  width: 23px;
}

.rt-event:nth-of-type(2n) {
  float: left;
  clear: left;
  text-align: right;
  padding-left: 0;
  padding-right: 65px;
}

.rt-event:nth-of-type(2n) svg {
  transform: scale(1, 1);
}

.rt-event:nth-of-type(2n) .rt-arrow {
  left: auto;
  right: -42px;
}

.rt-event:nth-of-type(2n) .rt-dot {
  left: auto;
  right: -101px;
}

.rt-timeline li:nth-child(3) {
  margin-top: 400px;
}

.rt-title {
  margin-bottom: 8px;
  font-size: 18px;
  font-weight: var(--medium) !important;
}

.rt-date {
  margin: 0;
  color: #a8a8a8;
  font-weight: var(--medium) !important;
  font-size: 14px;
}

.rt-header-container {
  margin-bottom: 30px;
}

.rt-image-container {
  margin-bottom: 15px;
}

.rt-image {
  vertical-align: top;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  width: 100%;
  height: auto;
  max-width: 100vw;
  max-height: 250px;
  object-fit: cover;
}

.rt-footer-container {
  margin: 15px -20px -20px;
  padding: 20px;
  text-align: center;
}

.rt-btn {
  text-align: center;
  font-size: 16px;
  font-weight: var(--medium) !important;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
  text-transform: capitalize;
}

.rt-text-container {
  max-height: 200px;
  font-weight: 300;
  overflow: hidden;
  text-overflow: ellipsis;
  content: '';
  position: relative;
  text-align: left;
}

.rt-text-container:before {
  content: '';
  font-weight: 300;
  width: 100%;
  height: 80px;
  position: absolute;
  left: 0;
  top: 120px;
  background: transparent;
  /* For Safari 5.1 to 6.0 */
  /* For Opera 11.1 to 12.0 */
  /* For Firefox 3.6 to 15 */
  background: linear-gradient(rgba(255, 255, 255, 0), #f0f0f0);
  /* Standard syntax */
}

@media all and (max-width: 1919px) {
  .rt-label {
    font-size: 20px;
  }

  .rt-event {
    padding-left: 45px;
  }

  .rt-event:nth-of-type(2n) {
    padding-right: 45px;
  }

  .rt-event .rt-dot {
    left: -81px;
  }

  .rt-event:nth-of-type(2n) .rt-dot {
    right: -81px;
  }

  .rt-header-container {
    margin-bottom: 20px;
  }

  .rt-title {
    font-size: 16px;
  }
}

@media all and (max-width: 959px) {
  .rt-timeline-container {
    padding: 0;
  }

  .rt-event {
    width: 100%;
    padding-left: 90px;
    min-height: 100px;
    margin-bottom: 30px;
    margin-top: 0;
  }

  .rt-event:nth-of-type(2n) {
    padding-right: 0;
    padding-left: 90px;
    text-align: left;
  }

  .rt-backing {
    padding: 20px;
  }

  .rt-event .rt-svg-container .rt-arrow {
    left: -43px;
  }

  .rt-event:nth-of-type(2n) .rt-arrow {
    left: -43px;
    right: auto; /* IE 9 */ /* Safari prior 9.0 */
    transform: rotate(180deg); /* Standard syntax */
  }

  .rt-event .rt-dot {
    left: -95px;
  }

  .rt-event:nth-of-type(2n) .rt-dot {
    right: auto;
    left: -95px;
  }

  .rt-label-container {
    justify-content: flex-start;
  }

  .rt-timeline:after {
    left: 30px;
  }

  .rt-timeline li:nth-child(3) {
    margin-top: 0;
  }

  .rt-footer-container {
    margin: 15px -20px -20px;
    padding: 15px 20px;
  }

  .rt-label-container:last-of-type {
    margin-top: 0;
  }
}

@media all and (max-width: 499px) {
  .rt-timeline-container {
    padding: 12px;
    min-width: 400px;
    margin-left: -16px;
  }

  .rt-label {
    font-size: 16px;
  }

  .rt-timeline::after {
    left: 25px;
  }

  .rt-event,
  .rt-event:nth-of-type(2n) {
    padding-left: 70px;
  }

  .rt-event .rt-dot,
  .rt-event:nth-of-type(2n) .rt-dot {
    left: -80px;
  }

  .rt-event .rt-svg-container .rt-arrow,
  .rt-event:nth-of-type(2n) .rt-arrow {
    left: -40px;
  }

  .rt-title {
    font-size: 18px;
  }

  .rt-header-container {
    margin-bottom: 10px;
  }
}

/*Slider Style*/
.slick-slider {
  padding-bottom: 20px;
}

.slick-slide img {
  display: inline-block;
  height: 190px;
  object-fit: contain;
}

.slick-dots {
  bottom: 0;
}

.slick-dots li {
  width: 12px;
  height: 12px;
  margin-right: 2px;
  margin-left: 2px;
}

.slick-dots li button {
  width: 12px;
  height: 12px;
  padding: 0;
}

.slick-dots li button:before {
  width: 12px;
  height: 12px;
  line-height: 12px;
  font-size: 10px;
  color: #be8658;
  opacity: 0.55;
}

.slick-dots li.slick-active button::before {
  color: #825a44;
  opacity: 0.75;
}

/*Apps style*/

.sc-launcher {
  cursor: pointer;
  z-index: 999;
  display: none;
}

.sc-header--img {
  width: 50px;
  height: 50px;
  padding: 0 !important;
  margin-right: 5px;
}

.sc-chat-window {
  bottom: 25px !important;
  z-index: 999;
  max-height: 380px !important;
}

.sc-header {
  padding: 12px 16px !important;
}

.sc-header--team-name {
  font-weight: var(--extra-bold) !important;
  font-size: 18px;
}

@media (max-width: 1367px) {
  .sc-header--img {
    width: 40px;
    height: 40px;
  }

  .sc-header {
    min-height: 65px;
  }

  .sc-header--team-name {
    font-size: 16px;
  }

  .sc-message--text {
    padding: 12px 16px !important;
  }
}

@media (max-width: 599px) {
  .sc-chat-window {
    width: 300px !important;
    height: 80% !important;
    border-radius: 10px !important;
    right: 10px !important;
    bottom: 10px !important;
    overflow: hidden;
  }
}

@font-face {
  font-family: Material-Design-Iconic-Font;
  src: url(https://xs770303.xsrv.jp/backoffice/static/media/Material-Design-Iconic-Font.a4d31128.woff2) format('woff2'),
    url(https://xs770303.xsrv.jp/backoffice/static/media/Material-Design-Iconic-Font.d2a55d33.woff) format('woff'),
    url(https://xs770303.xsrv.jp/backoffice/static/media/Material-Design-Iconic-Font.b351bd62.ttf) format('truetype');
}
.zmdi {
  display: inline-block;
  font: normal normal normal 14px/1 'Material-Design-Iconic-Font';
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.zmdi-hc-lg {
  font-size: 1.33333333em;
  line-height: 0.75em;
  vertical-align: -15%;
}
.zmdi-hc-2x {
  font-size: 2em;
}
.zmdi-hc-3x {
  font-size: 3em;
}
.zmdi-hc-4x {
  font-size: 4em;
}
.zmdi-hc-5x {
  font-size: 5em;
}
.zmdi-hc-fw {
  width: 1.28571429em;
  text-align: center;
}
.zmdi-hc-ul {
  padding-left: 0;
  margin-left: 2.14285714em;
  list-style-type: none;
}
.zmdi-hc-ul > li {
  position: relative;
}
.zmdi-hc-li {
  position: absolute;
  left: -2.14285714em;
  width: 2.14285714em;
  top: 0.14285714em;
  text-align: center;
}
.zmdi-hc-li.zmdi-hc-lg {
  left: -1.85714286em;
}
.zmdi-hc-border {
  padding: 0.1em 0.25em;
  border: solid 0.1em #9e9e9e;
  border-radius: 2px;
}
.zmdi-hc-border-circle {
  padding: 0.1em 0.25em;
  border: solid 0.1em #9e9e9e;
  border-radius: 50%;
}
.zmdi.pull-left {
  float: left;
  margin-right: 0.15em;
}
.zmdi.pull-right {
  float: right;
  margin-left: 0.15em;
}
.zmdi-hc-spin {
  animation: zmdi-spin 1.5s infinite linear;
}
.zmdi-hc-spin-reverse {
  animation: zmdi-spin-reverse 1.5s infinite linear;
}
@keyframes zmdi-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
@keyframes zmdi-spin-reverse {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-359deg);
  }
}
.zmdi-hc-rotate-90 {
  transform: rotate(90deg);
}
.zmdi-hc-rotate-180 {
  transform: rotate(180deg);
}
.zmdi-hc-rotate-270 {
  transform: rotate(270deg);
}
.zmdi-hc-flip-horizontal {
  transform: scale(-1, 1);
}
.zmdi-hc-flip-vertical {
  transform: scale(1, -1);
}
.zmdi-hc-stack {
  position: relative;
  display: inline-block;
  width: 2em;
  height: 2em;
  line-height: 2em;
  vertical-align: middle;
}
.zmdi-hc-stack-1x,
.zmdi-hc-stack-2x {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
}
.zmdi-hc-stack-1x {
  line-height: inherit;
}
.zmdi-hc-stack-2x {
  font-size: 2em;
}
.zmdi-hc-inverse {
  color: #fff;
}
.zmdi-3d-rotation:before {
  content: '\f101';
}
.zmdi-airplane-off:before {
  content: '\f102';
}
.zmdi-airplane:before {
  content: '\f103';
}
.zmdi-album:before {
  content: '\f104';
}
.zmdi-archive:before {
  content: '\f105';
}
.zmdi-assignment-account:before {
  content: '\f106';
}
.zmdi-assignment-alert:before {
  content: '\f107';
}
.zmdi-assignment-check:before {
  content: '\f108';
}
.zmdi-assignment-o:before {
  content: '\f109';
}
.zmdi-assignment-return:before {
  content: '\f10a';
}
.zmdi-assignment-returned:before {
  content: '\f10b';
}
.zmdi-assignment:before {
  content: '\f10c';
}
.zmdi-attachment-alt:before {
  content: '\f10d';
}
.zmdi-attachment:before {
  content: '\f10e';
}
.zmdi-audio:before {
  content: '\f10f';
}
.zmdi-badge-check:before {
  content: '\f110';
}
.zmdi-balance-wallet:before {
  content: '\f111';
}
.zmdi-balance:before {
  content: '\f112';
}
.zmdi-battery-alert:before {
  content: '\f113';
}
.zmdi-battery-flash:before {
  content: '\f114';
}
.zmdi-battery-unknown:before {
  content: '\f115';
}
.zmdi-battery:before {
  content: '\f116';
}
.zmdi-bike:before {
  content: '\f117';
}
.zmdi-block-alt:before {
  content: '\f118';
}
.zmdi-block:before {
  content: '\f119';
}
.zmdi-boat:before {
  content: '\f11a';
}
.zmdi-book-image:before {
  content: '\f11b';
}
.zmdi-book:before {
  content: '\f11c';
}
.zmdi-bookmark-outline:before {
  content: '\f11d';
}
.zmdi-bookmark:before {
  content: '\f11e';
}
.zmdi-brush:before {
  content: '\f11f';
}
.zmdi-bug:before {
  content: '\f120';
}
.zmdi-bus:before {
  content: '\f121';
}
.zmdi-cake:before {
  content: '\f122';
}
.zmdi-car-taxi:before {
  content: '\f123';
}
.zmdi-car-wash:before {
  content: '\f124';
}
.zmdi-car:before {
  content: '\f125';
}
.zmdi-card-giftcard:before {
  content: '\f126';
}
.zmdi-card-membership:before {
  content: '\f127';
}
.zmdi-card-travel:before {
  content: '\f128';
}
.zmdi-card:before {
  content: '\f129';
}
.zmdi-case-check:before {
  content: '\f12a';
}
.zmdi-case-download:before {
  content: '\f12b';
}
.zmdi-case-play:before {
  content: '\f12c';
}
.zmdi-case:before {
  content: '\f12d';
}
.zmdi-cast-connected:before {
  content: '\f12e';
}
.zmdi-cast:before {
  content: '\f12f';
}
.zmdi-chart-donut:before {
  content: '\f130';
}
.zmdi-chart:before {
  content: '\f131';
}
.zmdi-city-alt:before {
  content: '\f132';
}
.zmdi-city:before {
  content: '\f133';
}
.zmdi-close-circle-o:before {
  content: '\f134';
}
.zmdi-close-circle:before {
  content: '\f135';
}
.zmdi-close:before {
  content: '\f136';
}
.zmdi-cocktail:before {
  content: '\f137';
}
.zmdi-code-setting:before {
  content: '\f138';
}
.zmdi-code-smartphone:before {
  content: '\f139';
}
.zmdi-code:before {
  content: '\f13a';
}
.zmdi-coffee:before {
  content: '\f13b';
}
.zmdi-collection-bookmark:before {
  content: '\f13c';
}
.zmdi-collection-case-play:before {
  content: '\f13d';
}
.zmdi-collection-folder-image:before {
  content: '\f13e';
}
.zmdi-collection-image-o:before {
  content: '\f13f';
}
.zmdi-collection-image:before {
  content: '\f140';
}
.zmdi-collection-item-1:before {
  content: '\f141';
}
.zmdi-collection-item-2:before {
  content: '\f142';
}
.zmdi-collection-item-3:before {
  content: '\f143';
}
.zmdi-collection-item-4:before {
  content: '\f144';
}
.zmdi-collection-item-5:before {
  content: '\f145';
}
.zmdi-collection-item-6:before {
  content: '\f146';
}
.zmdi-collection-item-7:before {
  content: '\f147';
}
.zmdi-collection-item-8:before {
  content: '\f148';
}
.zmdi-collection-item-9-plus:before {
  content: '\f149';
}
.zmdi-collection-item-9:before {
  content: '\f14a';
}
.zmdi-collection-item:before {
  content: '\f14b';
}
.zmdi-collection-music:before {
  content: '\f14c';
}
.zmdi-collection-pdf:before {
  content: '\f14d';
}
.zmdi-collection-plus:before {
  content: '\f14e';
}
.zmdi-collection-speaker:before {
  content: '\f14f';
}
.zmdi-collection-text:before {
  content: '\f150';
}
.zmdi-collection-video:before {
  content: '\f151';
}
.zmdi-compass:before {
  content: '\f152';
}
.zmdi-cutlery:before {
  content: '\f153';
}
.zmdi-delete:before {
  content: '\f154';
}
.zmdi-dialpad:before {
  content: '\f155';
}
.zmdi-dns:before {
  content: '\f156';
}
.zmdi-drink:before {
  content: '\f157';
}
.zmdi-edit:before {
  content: '\f158';
}
.zmdi-email-open:before {
  content: '\f159';
}
.zmdi-email:before {
  content: '\f15a';
}
.zmdi-eye-off:before {
  content: '\f15b';
}
.zmdi-eye:before {
  content: '\f15c';
}
.zmdi-eyedropper:before {
  content: '\f15d';
}
.zmdi-favorite-outline:before {
  content: '\f15e';
}
.zmdi-favorite:before {
  content: '\f15f';
}
.zmdi-filter-list:before {
  content: '\f160';
}
.zmdi-fire:before {
  content: '\f161';
}
.zmdi-flag:before {
  content: '\f162';
}
.zmdi-flare:before {
  content: '\f163';
}
.zmdi-flash-auto:before {
  content: '\f164';
}
.zmdi-flash-off:before {
  content: '\f165';
}
.zmdi-flash:before {
  content: '\f166';
}
.zmdi-flip:before {
  content: '\f167';
}
.zmdi-flower-alt:before {
  content: '\f168';
}
.zmdi-flower:before {
  content: '\f169';
}
.zmdi-font:before {
  content: '\f16a';
}
.zmdi-fullscreen-alt:before {
  content: '\f16b';
}
.zmdi-fullscreen-exit:before {
  content: '\f16c';
}
.zmdi-fullscreen:before {
  content: '\f16d';
}
.zmdi-functions:before {
  content: '\f16e';
}
.zmdi-gas-station:before {
  content: '\f16f';
}
.zmdi-gesture:before {
  content: '\f170';
}
.zmdi-globe-alt:before {
  content: '\f171';
}
.zmdi-globe-lock:before {
  content: '\f172';
}
.zmdi-globe:before {
  content: '\f173';
}
.zmdi-graduation-cap:before {
  content: '\f174';
}
.zmdi-home:before {
  content: '\f175';
}
.zmdi-hospital-alt:before {
  content: '\f176';
}
.zmdi-hospital:before {
  content: '\f177';
}
.zmdi-hotel:before {
  content: '\f178';
}
.zmdi-hourglass-alt:before {
  content: '\f179';
}
.zmdi-hourglass-outline:before {
  content: '\f17a';
}
.zmdi-hourglass:before {
  content: '\f17b';
}
.zmdi-http:before {
  content: '\f17c';
}
.zmdi-image-alt:before {
  content: '\f17d';
}
.zmdi-image-o:before {
  content: '\f17e';
}
.zmdi-image:before {
  content: '\f17f';
}
.zmdi-inbox:before {
  content: '\f180';
}
.zmdi-invert-colors-off:before {
  content: '\f181';
}
.zmdi-invert-colors:before {
  content: '\f182';
}
.zmdi-key:before {
  content: '\f183';
}
.zmdi-label-alt-outline:before {
  content: '\f184';
}
.zmdi-label-alt:before {
  content: '\f185';
}
.zmdi-label-heart:before {
  content: '\f186';
}
.zmdi-label:before {
  content: '\f187';
}
.zmdi-labels:before {
  content: '\f188';
}
.zmdi-lamp:before {
  content: '\f189';
}
.zmdi-landscape:before {
  content: '\f18a';
}
.zmdi-layers-off:before {
  content: '\f18b';
}
.zmdi-layers:before {
  content: '\f18c';
}
.zmdi-library:before {
  content: '\f18d';
}
.zmdi-link:before {
  content: '\f18e';
}
.zmdi-lock-open:before {
  content: '\f18f';
}
.zmdi-lock-outline:before {
  content: '\f190';
}
.zmdi-lock:before {
  content: '\f191';
}
.zmdi-mail-reply-all:before {
  content: '\f192';
}
.zmdi-mail-reply:before {
  content: '\f193';
}
.zmdi-mail-send:before {
  content: '\f194';
}
.zmdi-mall:before {
  content: '\f195';
}
.zmdi-map:before {
  content: '\f196';
}
.zmdi-menu:before {
  content: '\f197';
}
.zmdi-money-box:before {
  content: '\f198';
}
.zmdi-money-off:before {
  content: '\f199';
}
.zmdi-money:before {
  content: '\f19a';
}
.zmdi-more-vert:before {
  content: '\f19b';
}
.zmdi-more:before {
  content: '\f19c';
}
.zmdi-movie-alt:before {
  content: '\f19d';
}
.zmdi-movie:before {
  content: '\f19e';
}
.zmdi-nature-people:before {
  content: '\f19f';
}
.zmdi-nature:before {
  content: '\f1a0';
}
.zmdi-navigation:before {
  content: '\f1a1';
}
.zmdi-open-in-browser:before {
  content: '\f1a2';
}
.zmdi-open-in-new:before {
  content: '\f1a3';
}
.zmdi-palette:before {
  content: '\f1a4';
}
.zmdi-parking:before {
  content: '\f1a5';
}
.zmdi-pin-account:before {
  content: '\f1a6';
}
.zmdi-pin-assistant:before {
  content: '\f1a7';
}
.zmdi-pin-drop:before {
  content: '\f1a8';
}
.zmdi-pin-help:before {
  content: '\f1a9';
}
.zmdi-pin-off:before {
  content: '\f1aa';
}
.zmdi-pin:before {
  content: '\f1ab';
}
.zmdi-pizza:before {
  content: '\f1ac';
}
.zmdi-plaster:before {
  content: '\f1ad';
}
.zmdi-power-setting:before {
  content: '\f1ae';
}
.zmdi-power:before {
  content: '\f1af';
}
.zmdi-print:before {
  content: '\f1b0';
}
.zmdi-puzzle-piece:before {
  content: '\f1b1';
}
.zmdi-quote:before {
  content: '\f1b2';
}
.zmdi-railway:before {
  content: '\f1b3';
}
.zmdi-receipt:before {
  content: '\f1b4';
}
.zmdi-refresh-alt:before {
  content: '\f1b5';
}
.zmdi-refresh-sync-alert:before {
  content: '\f1b6';
}
.zmdi-refresh-sync-off:before {
  content: '\f1b7';
}
.zmdi-refresh-sync:before {
  content: '\f1b8';
}
.zmdi-refresh:before {
  content: '\f1b9';
}
.zmdi-roller:before {
  content: '\f1ba';
}
.zmdi-ruler:before {
  content: '\f1bb';
}
.zmdi-scissors:before {
  content: '\f1bc';
}
.zmdi-screen-rotation-lock:before {
  content: '\f1bd';
}
.zmdi-screen-rotation:before {
  content: '\f1be';
}
.zmdi-search-for:before {
  content: '\f1bf';
}
.zmdi-search-in-file:before {
  content: '\f1c0';
}
.zmdi-search-in-page:before {
  content: '\f1c1';
}
.zmdi-search-replace:before {
  content: '\f1c2';
}
.zmdi-search:before {
  content: '\f1c3';
}
.zmdi-seat:before {
  content: '\f1c4';
}
.zmdi-settings-square:before {
  content: '\f1c5';
}
.zmdi-settings:before {
  content: '\f1c6';
}
.zmdi-shield-check:before {
  content: '\f1c7';
}
.zmdi-shield-security:before {
  content: '\f1c8';
}
.zmdi-shopping-basket:before {
  content: '\f1c9';
}
.zmdi-shopping-cart-plus:before {
  content: '\f1ca';
}
.zmdi-shopping-cart:before {
  content: '\f1cb';
}
.zmdi-sign-in:before {
  content: '\f1cc';
}
.zmdi-sort-amount-asc:before {
  content: '\f1cd';
}
.zmdi-sort-amount-desc:before {
  content: '\f1ce';
}
.zmdi-sort-asc:before {
  content: '\f1cf';
}
.zmdi-sort-desc:before {
  content: '\f1d0';
}
.zmdi-spellcheck:before {
  content: '\f1d1';
}
.zmdi-storage:before {
  content: '\f1d2';
}
.zmdi-store-24:before {
  content: '\f1d3';
}
.zmdi-store:before {
  content: '\f1d4';
}
.zmdi-subway:before {
  content: '\f1d5';
}
.zmdi-sun:before {
  content: '\f1d6';
}
.zmdi-tab-unselected:before {
  content: '\f1d7';
}
.zmdi-tab:before {
  content: '\f1d8';
}
.zmdi-tag-close:before {
  content: '\f1d9';
}
.zmdi-tag-more:before {
  content: '\f1da';
}
.zmdi-tag:before {
  content: '\f1db';
}
.zmdi-thumb-down:before {
  content: '\f1dc';
}
.zmdi-thumb-up-down:before {
  content: '\f1dd';
}
.zmdi-thumb-up:before {
  content: '\f1de';
}
.zmdi-ticket-star:before {
  content: '\f1df';
}
.zmdi-toll:before {
  content: '\f1e0';
}
.zmdi-toys:before {
  content: '\f1e1';
}
.zmdi-traffic:before {
  content: '\f1e2';
}
.zmdi-translate:before {
  content: '\f1e3';
}
.zmdi-triangle-down:before {
  content: '\f1e4';
}
.zmdi-triangle-up:before {
  content: '\f1e5';
}
.zmdi-truck:before {
  content: '\f1e6';
}
.zmdi-turning-sign:before {
  content: '\f1e7';
}
.zmdi-wallpaper:before {
  content: '\f1e8';
}
.zmdi-washing-machine:before {
  content: '\f1e9';
}
.zmdi-window-maximize:before {
  content: '\f1ea';
}
.zmdi-window-minimize:before {
  content: '\f1eb';
}
.zmdi-window-restore:before {
  content: '\f1ec';
}
.zmdi-wrench:before {
  content: '\f1ed';
}
.zmdi-zoom-in:before {
  content: '\f1ee';
}
.zmdi-zoom-out:before {
  content: '\f1ef';
}
.zmdi-alert-circle-o:before {
  content: '\f1f0';
}
.zmdi-alert-circle:before {
  content: '\f1f1';
}
.zmdi-alert-octagon:before {
  content: '\f1f2';
}
.zmdi-alert-polygon:before {
  content: '\f1f3';
}
.zmdi-alert-triangle:before {
  content: '\f1f4';
}
.zmdi-help-outline:before {
  content: '\f1f5';
}
.zmdi-help:before {
  content: '\f1f6';
}
.zmdi-info-outline:before {
  content: '\f1f7';
}
.zmdi-info:before {
  content: '\f1f8';
}
.zmdi-notifications-active:before {
  content: '\f1f9';
}
.zmdi-notifications-add:before {
  content: '\f1fa';
}
.zmdi-notifications-none:before {
  content: '\f1fb';
}
.zmdi-notifications-off:before {
  content: '\f1fc';
}
.zmdi-notifications-paused:before {
  content: '\f1fd';
}
.zmdi-notifications:before {
  content: '\f1fe';
}
.zmdi-account-add:before {
  content: '\f1ff';
}
.zmdi-account-box-mail:before {
  content: '\f200';
}
.zmdi-account-box-o:before {
  content: '\f201';
}
.zmdi-account-box-phone:before {
  content: '\f202';
}
.zmdi-account-box:before {
  content: '\f203';
}
.zmdi-account-calendar:before {
  content: '\f204';
}
.zmdi-account-circle:before {
  content: '\f205';
}
.zmdi-account-o:before {
  content: '\f206';
}
.zmdi-account:before {
  content: '\f207';
}
.zmdi-accounts-add:before {
  content: '\f208';
}
.zmdi-accounts-alt:before {
  content: '\f209';
}
.zmdi-accounts-list-alt:before {
  content: '\f20a';
}
.zmdi-accounts-list:before {
  content: '\f20b';
}
.zmdi-accounts-outline:before {
  content: '\f20c';
}
.zmdi-accounts:before {
  content: '\f20d';
}
.zmdi-face:before {
  content: '\f20e';
}
.zmdi-female:before {
  content: '\f20f';
}
.zmdi-male-alt:before {
  content: '\f210';
}
.zmdi-male-female:before {
  content: '\f211';
}
.zmdi-male:before {
  content: '\f212';
}
.zmdi-mood-bad:before {
  content: '\f213';
}
.zmdi-mood:before {
  content: '\f214';
}
.zmdi-run:before {
  content: '\f215';
}
.zmdi-walk:before {
  content: '\f216';
}
.zmdi-cloud-box:before {
  content: '\f217';
}
.zmdi-cloud-circle:before {
  content: '\f218';
}
.zmdi-cloud-done:before {
  content: '\f219';
}
.zmdi-cloud-download:before {
  content: '\f21a';
}
.zmdi-cloud-off:before {
  content: '\f21b';
}
.zmdi-cloud-outline-alt:before {
  content: '\f21c';
}
.zmdi-cloud-outline:before {
  content: '\f21d';
}
.zmdi-cloud-upload:before {
  content: '\f21e';
}
.zmdi-cloud:before {
  content: '\f21f';
}
.zmdi-download:before {
  content: '\f220';
}
.zmdi-file-plus:before {
  content: '\f221';
}
.zmdi-file-text:before {
  content: '\f222';
}
.zmdi-file:before {
  content: '\f223';
}
.zmdi-folder-outline:before {
  content: '\f224';
}
.zmdi-folder-person:before {
  content: '\f225';
}
.zmdi-folder-star-alt:before {
  content: '\f226';
}
.zmdi-folder-star:before {
  content: '\f227';
}
.zmdi-folder:before {
  content: '\f228';
}
.zmdi-gif:before {
  content: '\f229';
}
.zmdi-upload:before {
  content: '\f22a';
}
.zmdi-border-all:before {
  content: '\f22b';
}
.zmdi-border-bottom:before {
  content: '\f22c';
}
.zmdi-border-clear:before {
  content: '\f22d';
}
.zmdi-border-color:before {
  content: '\f22e';
}
.zmdi-border-horizontal:before {
  content: '\f22f';
}
.zmdi-border-inner:before {
  content: '\f230';
}
.zmdi-border-left:before {
  content: '\f231';
}
.zmdi-border-outer:before {
  content: '\f232';
}
.zmdi-border-right:before {
  content: '\f233';
}
.zmdi-border-style:before {
  content: '\f234';
}
.zmdi-border-top:before {
  content: '\f235';
}
.zmdi-border-vertical:before {
  content: '\f236';
}
.zmdi-copy:before {
  content: '\f237';
}
.zmdi-crop:before {
  content: '\f238';
}
.zmdi-format-align-center:before {
  content: '\f239';
}
.zmdi-format-align-justify:before {
  content: '\f23a';
}
.zmdi-format-align-left:before {
  content: '\f23b';
}
.zmdi-format-align-right:before {
  content: '\f23c';
}
.zmdi-format-bold:before {
  content: '\f23d';
}
.zmdi-format-clear-all:before {
  content: '\f23e';
}
.zmdi-format-clear:before {
  content: '\f23f';
}
.zmdi-format-color-fill:before {
  content: '\f240';
}
.zmdi-format-color-reset:before {
  content: '\f241';
}
.zmdi-format-color-text:before {
  content: '\f242';
}
.zmdi-format-indent-decrease:before {
  content: '\f243';
}
.zmdi-format-indent-increase:before {
  content: '\f244';
}
.zmdi-format-italic:before {
  content: '\f245';
}
.zmdi-format-line-spacing:before {
  content: '\f246';
}
.zmdi-format-list-bulleted:before {
  content: '\f247';
}
.zmdi-format-list-numbered:before {
  content: '\f248';
}
.zmdi-format-ltr:before {
  content: '\f249';
}
.zmdi-format-rtl:before {
  content: '\f24a';
}
.zmdi-format-size:before {
  content: '\f24b';
}
.zmdi-format-strikethrough-s:before {
  content: '\f24c';
}
.zmdi-format-strikethrough:before {
  content: '\f24d';
}
.zmdi-format-subject:before {
  content: '\f24e';
}
.zmdi-format-underlined:before {
  content: '\f24f';
}
.zmdi-format-valign-bottom:before {
  content: '\f250';
}
.zmdi-format-valign-center:before {
  content: '\f251';
}
.zmdi-format-valign-top:before {
  content: '\f252';
}
.zmdi-redo:before {
  content: '\f253';
}
.zmdi-select-all:before {
  content: '\f254';
}
.zmdi-space-bar:before {
  content: '\f255';
}
.zmdi-text-format:before {
  content: '\f256';
}
.zmdi-transform:before {
  content: '\f257';
}
.zmdi-undo:before {
  content: '\f258';
}
.zmdi-wrap-text:before {
  content: '\f259';
}
.zmdi-comment-alert:before {
  content: '\f25a';
}
.zmdi-comment-alt-text:before {
  content: '\f25b';
}
.zmdi-comment-alt:before {
  content: '\f25c';
}
.zmdi-comment-edit:before {
  content: '\f25d';
}
.zmdi-comment-image:before {
  content: '\f25e';
}
.zmdi-comment-list:before {
  content: '\f25f';
}
.zmdi-comment-more:before {
  content: '\f260';
}
.zmdi-comment-outline:before {
  content: '\f261';
}
.zmdi-comment-text-alt:before {
  content: '\f262';
}
.zmdi-comment-text:before {
  content: '\f263';
}
.zmdi-comment-video:before {
  content: '\f264';
}
.zmdi-comment:before {
  content: '\f265';
}
.zmdi-comments:before {
  content: '\f266';
}
.zmdi-check-all:before {
  content: '\f267';
}
.zmdi-check-circle-u:before {
  content: '\f268';
}
.zmdi-check-circle:before {
  content: '\f269';
}
.zmdi-check-square:before {
  content: '\f26a';
}
.zmdi-check:before {
  content: '\f26b';
}
.zmdi-circle-o:before {
  content: '\f26c';
}
.zmdi-circle:before {
  content: '\f26d';
}
.zmdi-dot-circle-alt:before {
  content: '\f26e';
}
.zmdi-dot-circle:before {
  content: '\f26f';
}
.zmdi-minus-circle-outline:before {
  content: '\f270';
}
.zmdi-minus-circle:before {
  content: '\f271';
}
.zmdi-minus-square:before {
  content: '\f272';
}
.zmdi-minus:before {
  content: '\f273';
}
.zmdi-plus-circle-o-duplicate:before {
  content: '\f274';
}
.zmdi-plus-circle-o:before {
  content: '\f275';
}
.zmdi-plus-circle:before {
  content: '\f276';
}
.zmdi-plus-square:before {
  content: '\f277';
}
.zmdi-plus:before {
  content: '\f278';
}
.zmdi-square-o:before {
  content: '\f279';
}
.zmdi-star-circle:before {
  content: '\f27a';
}
.zmdi-star-half:before {
  content: '\f27b';
}
.zmdi-star-outline:before {
  content: '\f27c';
}
.zmdi-star:before {
  content: '\f27d';
}
.zmdi-bluetooth-connected:before {
  content: '\f27e';
}
.zmdi-bluetooth-off:before {
  content: '\f27f';
}
.zmdi-bluetooth-search:before {
  content: '\f280';
}
.zmdi-bluetooth-setting:before {
  content: '\f281';
}
.zmdi-bluetooth:before {
  content: '\f282';
}
.zmdi-camera-add:before {
  content: '\f283';
}
.zmdi-camera-alt:before {
  content: '\f284';
}
.zmdi-camera-bw:before {
  content: '\f285';
}
.zmdi-camera-front:before {
  content: '\f286';
}
.zmdi-camera-mic:before {
  content: '\f287';
}
.zmdi-camera-party-mode:before {
  content: '\f288';
}
.zmdi-camera-rear:before {
  content: '\f289';
}
.zmdi-camera-roll:before {
  content: '\f28a';
}
.zmdi-camera-switch:before {
  content: '\f28b';
}
.zmdi-camera:before {
  content: '\f28c';
}
.zmdi-card-alert:before {
  content: '\f28d';
}
.zmdi-card-off:before {
  content: '\f28e';
}
.zmdi-card-sd:before {
  content: '\f28f';
}
.zmdi-card-sim:before {
  content: '\f290';
}
.zmdi-desktop-mac:before {
  content: '\f291';
}
.zmdi-desktop-windows:before {
  content: '\f292';
}
.zmdi-device-hub:before {
  content: '\f293';
}
.zmdi-devices-off:before {
  content: '\f294';
}
.zmdi-devices:before {
  content: '\f295';
}
.zmdi-dock:before {
  content: '\f296';
}
.zmdi-floppy:before {
  content: '\f297';
}
.zmdi-gamepad:before {
  content: '\f298';
}
.zmdi-gps-dot:before {
  content: '\f299';
}
.zmdi-gps-off:before {
  content: '\f29a';
}
.zmdi-gps:before {
  content: '\f29b';
}
.zmdi-headset-mic:before {
  content: '\f29c';
}
.zmdi-headset:before {
  content: '\f29d';
}
.zmdi-input-antenna:before {
  content: '\f29e';
}
.zmdi-input-composite:before {
  content: '\f29f';
}
.zmdi-input-hdmi:before {
  content: '\f2a0';
}
.zmdi-input-power:before {
  content: '\f2a1';
}
.zmdi-input-svideo:before {
  content: '\f2a2';
}
.zmdi-keyboard-hide:before {
  content: '\f2a3';
}
.zmdi-keyboard:before {
  content: '\f2a4';
}
.zmdi-laptop-chromebook:before {
  content: '\f2a5';
}
.zmdi-laptop-mac:before {
  content: '\f2a6';
}
.zmdi-laptop:before {
  content: '\f2a7';
}
.zmdi-mic-off:before {
  content: '\f2a8';
}
.zmdi-mic-outline:before {
  content: '\f2a9';
}
.zmdi-mic-setting:before {
  content: '\f2aa';
}
.zmdi-mic:before {
  content: '\f2ab';
}
.zmdi-mouse:before {
  content: '\f2ac';
}
.zmdi-network-alert:before {
  content: '\f2ad';
}
.zmdi-network-locked:before {
  content: '\f2ae';
}
.zmdi-network-off:before {
  content: '\f2af';
}
.zmdi-network-outline:before {
  content: '\f2b0';
}
.zmdi-network-setting:before {
  content: '\f2b1';
}
.zmdi-network:before {
  content: '\f2b2';
}
.zmdi-phone-bluetooth:before {
  content: '\f2b3';
}
.zmdi-phone-end:before {
  content: '\f2b4';
}
.zmdi-phone-forwarded:before {
  content: '\f2b5';
}
.zmdi-phone-in-talk:before {
  content: '\f2b6';
}
.zmdi-phone-locked:before {
  content: '\f2b7';
}
.zmdi-phone-missed:before {
  content: '\f2b8';
}
.zmdi-phone-msg:before {
  content: '\f2b9';
}
.zmdi-phone-paused:before {
  content: '\f2ba';
}
.zmdi-phone-ring:before {
  content: '\f2bb';
}
.zmdi-phone-setting:before {
  content: '\f2bc';
}
.zmdi-phone-sip:before {
  content: '\f2bd';
}
.zmdi-phone:before {
  content: '\f2be';
}
.zmdi-portable-wifi-changes:before {
  content: '\f2bf';
}
.zmdi-portable-wifi-off:before {
  content: '\f2c0';
}
.zmdi-portable-wifi:before {
  content: '\f2c1';
}
.zmdi-radio:before {
  content: '\f2c2';
}
.zmdi-reader:before {
  content: '\f2c3';
}
.zmdi-remote-control-alt:before {
  content: '\f2c4';
}
.zmdi-remote-control:before {
  content: '\f2c5';
}
.zmdi-router:before {
  content: '\f2c6';
}
.zmdi-scanner:before {
  content: '\f2c7';
}
.zmdi-smartphone-android:before {
  content: '\f2c8';
}
.zmdi-smartphone-download:before {
  content: '\f2c9';
}
.zmdi-smartphone-erase:before {
  content: '\f2ca';
}
.zmdi-smartphone-info:before {
  content: '\f2cb';
}
.zmdi-smartphone-iphone:before {
  content: '\f2cc';
}
.zmdi-smartphone-landscape-lock:before {
  content: '\f2cd';
}
.zmdi-smartphone-landscape:before {
  content: '\f2ce';
}
.zmdi-smartphone-lock:before {
  content: '\f2cf';
}
.zmdi-smartphone-portrait-lock:before {
  content: '\f2d0';
}
.zmdi-smartphone-ring:before {
  content: '\f2d1';
}
.zmdi-smartphone-setting:before {
  content: '\f2d2';
}
.zmdi-smartphone-setup:before {
  content: '\f2d3';
}
.zmdi-smartphone:before {
  content: '\f2d4';
}
.zmdi-speaker:before {
  content: '\f2d5';
}
.zmdi-tablet-android:before {
  content: '\f2d6';
}
.zmdi-tablet-mac:before {
  content: '\f2d7';
}
.zmdi-tablet:before {
  content: '\f2d8';
}
.zmdi-tv-alt-play:before {
  content: '\f2d9';
}
.zmdi-tv-list:before {
  content: '\f2da';
}
.zmdi-tv-play:before {
  content: '\f2db';
}
.zmdi-tv:before {
  content: '\f2dc';
}
.zmdi-usb:before {
  content: '\f2dd';
}
.zmdi-videocam-off:before {
  content: '\f2de';
}
.zmdi-videocam-switch:before {
  content: '\f2df';
}
.zmdi-videocam:before {
  content: '\f2e0';
}
.zmdi-watch:before {
  content: '\f2e1';
}
.zmdi-wifi-alt-2:before {
  content: '\f2e2';
}
.zmdi-wifi-alt:before {
  content: '\f2e3';
}
.zmdi-wifi-info:before {
  content: '\f2e4';
}
.zmdi-wifi-lock:before {
  content: '\f2e5';
}
.zmdi-wifi-off:before {
  content: '\f2e6';
}
.zmdi-wifi-outline:before {
  content: '\f2e7';
}
.zmdi-wifi:before {
  content: '\f2e8';
}
.zmdi-arrow-left-bottom:before {
  content: '\f2e9';
}
.zmdi-arrow-left:before {
  content: '\f2ea';
}
.zmdi-arrow-merge:before {
  content: '\f2eb';
}
.zmdi-arrow-missed:before {
  content: '\f2ec';
}
.zmdi-arrow-right-top:before {
  content: '\f2ed';
}
.zmdi-arrow-right:before {
  content: '\f2ee';
}
.zmdi-arrow-split:before {
  content: '\f2ef';
}
.zmdi-arrows:before {
  content: '\f2f0';
}
.zmdi-caret-down-circle:before {
  content: '\f2f1';
}
.zmdi-caret-down:before {
  content: '\f2f2';
}
.zmdi-caret-left-circle:before {
  content: '\f2f3';
}
.zmdi-caret-left:before {
  content: '\f2f4';
}
.zmdi-caret-right-circle:before {
  content: '\f2f5';
}
.zmdi-caret-right:before {
  content: '\f2f6';
}
.zmdi-caret-up-circle:before {
  content: '\f2f7';
}
.zmdi-caret-up:before {
  content: '\f2f8';
}
.zmdi-chevron-down:before {
  content: '\f2f9';
}
.zmdi-chevron-left:before {
  content: '\f2fa';
}
.zmdi-chevron-right:before {
  content: '\f2fb';
}
.zmdi-chevron-up:before {
  content: '\f2fc';
}
.zmdi-forward:before {
  content: '\f2fd';
}
.zmdi-long-arrow-down:before {
  content: '\f2fe';
}
.zmdi-long-arrow-left:before {
  content: '\f2ff';
}
.zmdi-long-arrow-return:before {
  content: '\f300';
}
.zmdi-long-arrow-right:before {
  content: '\f301';
}
.zmdi-long-arrow-tab:before {
  content: '\f302';
}
.zmdi-long-arrow-up:before {
  content: '\f303';
}
.zmdi-rotate-ccw:before {
  content: '\f304';
}
.zmdi-rotate-cw:before {
  content: '\f305';
}
.zmdi-rotate-left:before {
  content: '\f306';
}
.zmdi-rotate-right:before {
  content: '\f307';
}
.zmdi-square-down:before {
  content: '\f308';
}
.zmdi-square-right:before {
  content: '\f309';
}
.zmdi-swap-alt:before {
  content: '\f30a';
}
.zmdi-swap-vertical-circle:before {
  content: '\f30b';
}
.zmdi-swap-vertical:before {
  content: '\f30c';
}
.zmdi-swap:before {
  content: '\f30d';
}
.zmdi-trending-down:before {
  content: '\f30e';
}
.zmdi-trending-flat:before {
  content: '\f30f';
}
.zmdi-trending-up:before {
  content: '\f310';
}
.zmdi-unfold-less:before {
  content: '\f311';
}
.zmdi-unfold-more:before {
  content: '\f312';
}
.zmdi-apps:before {
  content: '\f313';
}
.zmdi-grid-off:before {
  content: '\f314';
}
.zmdi-grid:before {
  content: '\f315';
}
.zmdi-view-agenda:before {
  content: '\f316';
}
.zmdi-view-array:before {
  content: '\f317';
}
.zmdi-view-carousel:before {
  content: '\f318';
}
.zmdi-view-column:before {
  content: '\f319';
}
.zmdi-view-comfy:before {
  content: '\f31a';
}
.zmdi-view-compact:before {
  content: '\f31b';
}
.zmdi-view-dashboard:before {
  content: '\f31c';
}
.zmdi-view-day:before {
  content: '\f31d';
}
.zmdi-view-headline:before {
  content: '\f31e';
}
.zmdi-view-list-alt:before {
  content: '\f31f';
}
.zmdi-view-list:before {
  content: '\f320';
}
.zmdi-view-module:before {
  content: '\f321';
}
.zmdi-view-quilt:before {
  content: '\f322';
}
.zmdi-view-stream:before {
  content: '\f323';
}
.zmdi-view-subtitles:before {
  content: '\f324';
}
.zmdi-view-toc:before {
  content: '\f325';
}
.zmdi-view-web:before {
  content: '\f326';
}
.zmdi-view-week:before {
  content: '\f327';
}
.zmdi-widgets:before {
  content: '\f328';
}
.zmdi-alarm-check:before {
  content: '\f329';
}
.zmdi-alarm-off:before {
  content: '\f32a';
}
.zmdi-alarm-plus:before {
  content: '\f32b';
}
.zmdi-alarm-snooze:before {
  content: '\f32c';
}
.zmdi-alarm:before {
  content: '\f32d';
}
.zmdi-calendar-alt:before {
  content: '\f32e';
}
.zmdi-calendar-check:before {
  content: '\f32f';
}
.zmdi-calendar-close:before {
  content: '\f330';
}
.zmdi-calendar-note:before {
  content: '\f331';
}
.zmdi-calendar:before {
  content: '\f332';
}
.zmdi-time-countdown:before {
  content: '\f333';
}
.zmdi-time-interval:before {
  content: '\f334';
}
.zmdi-time-restore-setting:before {
  content: '\f335';
}
.zmdi-time-restore:before {
  content: '\f336';
}
.zmdi-time:before {
  content: '\f337';
}
.zmdi-timer-off:before {
  content: '\f338';
}
.zmdi-timer:before {
  content: '\f339';
}
.zmdi-android-alt:before {
  content: '\f33a';
}
.zmdi-android:before {
  content: '\f33b';
}
.zmdi-apple:before {
  content: '\f33c';
}
.zmdi-behance:before {
  content: '\f33d';
}
.zmdi-codepen:before {
  content: '\f33e';
}
.zmdi-dribbble:before {
  content: '\f33f';
}
.zmdi-dropbox:before {
  content: '\f340';
}
.zmdi-evernote:before {
  content: '\f341';
}
.zmdi-facebook-box:before {
  content: '\f342';
}
.zmdi-facebook:before {
  content: '\f343';
}
.zmdi-github-box:before {
  content: '\f344';
}
.zmdi-github:before {
  content: '\f345';
}
.zmdi-google-drive:before {
  content: '\f346';
}
.zmdi-google-earth:before {
  content: '\f347';
}
.zmdi-google-glass:before {
  content: '\f348';
}
.zmdi-google-maps:before {
  content: '\f349';
}
.zmdi-google-pages:before {
  content: '\f34a';
}
.zmdi-google-play:before {
  content: '\f34b';
}
.zmdi-google-plus-box:before {
  content: '\f34c';
}
.zmdi-google-plus:before {
  content: '\f34d';
}
.zmdi-google:before {
  content: '\f34e';
}
.zmdi-instagram:before {
  content: '\f34f';
}
.zmdi-language-css3:before {
  content: '\f350';
}
.zmdi-language-html5:before {
  content: '\f351';
}
.zmdi-language-javascript:before {
  content: '\f352';
}
.zmdi-language-python-alt:before {
  content: '\f353';
}
.zmdi-language-python:before {
  content: '\f354';
}
.zmdi-lastfm:before {
  content: '\f355';
}
.zmdi-linkedin-box:before {
  content: '\f356';
}
.zmdi-paypal:before {
  content: '\f357';
}
.zmdi-pinterest-box:before {
  content: '\f358';
}
.zmdi-pocket:before {
  content: '\f359';
}
.zmdi-polymer:before {
  content: '\f35a';
}
.zmdi-share:before {
  content: '\f35b';
}
.zmdi-stackoverflow:before {
  content: '\f35c';
}
.zmdi-steam-square:before {
  content: '\f35d';
}
.zmdi-steam:before {
  content: '\f35e';
}
.zmdi-twitter-box:before {
  content: '\f35f';
}
.zmdi-twitter:before {
  content: '\f360';
}
.zmdi-vk:before {
  content: '\f361';
}
.zmdi-wikipedia:before {
  content: '\f362';
}
.zmdi-windows:before {
  content: '\f363';
}
.zmdi-aspect-ratio-alt:before {
  content: '\f364';
}
.zmdi-aspect-ratio:before {
  content: '\f365';
}
.zmdi-blur-circular:before {
  content: '\f366';
}
.zmdi-blur-linear:before {
  content: '\f367';
}
.zmdi-blur-off:before {
  content: '\f368';
}
.zmdi-blur:before {
  content: '\f369';
}
.zmdi-brightness-2:before {
  content: '\f36a';
}
.zmdi-brightness-3:before {
  content: '\f36b';
}
.zmdi-brightness-4:before {
  content: '\f36c';
}
.zmdi-brightness-5:before {
  content: '\f36d';
}
.zmdi-brightness-6:before {
  content: '\f36e';
}
.zmdi-brightness-7:before {
  content: '\f36f';
}
.zmdi-brightness-auto:before {
  content: '\f370';
}
.zmdi-brightness-setting:before {
  content: '\f371';
}
.zmdi-broken-image:before {
  content: '\f372';
}
.zmdi-center-focus-strong:before {
  content: '\f373';
}
.zmdi-center-focus-weak:before {
  content: '\f374';
}
.zmdi-compare:before {
  content: '\f375';
}
.zmdi-crop-16-9:before {
  content: '\f376';
}
.zmdi-crop-3-2:before {
  content: '\f377';
}
.zmdi-crop-5-4:before {
  content: '\f378';
}
.zmdi-crop-7-5:before {
  content: '\f379';
}
.zmdi-crop-din:before {
  content: '\f37a';
}
.zmdi-crop-free:before {
  content: '\f37b';
}
.zmdi-crop-landscape:before {
  content: '\f37c';
}
.zmdi-crop-portrait:before {
  content: '\f37d';
}
.zmdi-crop-square:before {
  content: '\f37e';
}
.zmdi-exposure-alt:before {
  content: '\f37f';
}
.zmdi-exposure:before {
  content: '\f380';
}
.zmdi-filter-b-and-w:before {
  content: '\f381';
}
.zmdi-filter-center-focus:before {
  content: '\f382';
}
.zmdi-filter-frames:before {
  content: '\f383';
}
.zmdi-filter-tilt-shift:before {
  content: '\f384';
}
.zmdi-gradient:before {
  content: '\f385';
}
.zmdi-grain:before {
  content: '\f386';
}
.zmdi-graphic-eq:before {
  content: '\f387';
}
.zmdi-hdr-off:before {
  content: '\f388';
}
.zmdi-hdr-strong:before {
  content: '\f389';
}
.zmdi-hdr-weak:before {
  content: '\f38a';
}
.zmdi-hdr:before {
  content: '\f38b';
}
.zmdi-iridescent:before {
  content: '\f38c';
}
.zmdi-leak-off:before {
  content: '\f38d';
}
.zmdi-leak:before {
  content: '\f38e';
}
.zmdi-looks:before {
  content: '\f38f';
}
.zmdi-loupe:before {
  content: '\f390';
}
.zmdi-panorama-horizontal:before {
  content: '\f391';
}
.zmdi-panorama-vertical:before {
  content: '\f392';
}
.zmdi-panorama-wide-angle:before {
  content: '\f393';
}
.zmdi-photo-size-select-large:before {
  content: '\f394';
}
.zmdi-photo-size-select-small:before {
  content: '\f395';
}
.zmdi-picture-in-picture:before {
  content: '\f396';
}
.zmdi-slideshow:before {
  content: '\f397';
}
.zmdi-texture:before {
  content: '\f398';
}
.zmdi-tonality:before {
  content: '\f399';
}
.zmdi-vignette:before {
  content: '\f39a';
}
.zmdi-wb-auto:before {
  content: '\f39b';
}
.zmdi-eject-alt:before {
  content: '\f39c';
}
.zmdi-eject:before {
  content: '\f39d';
}
.zmdi-equalizer:before {
  content: '\f39e';
}
.zmdi-fast-forward:before {
  content: '\f39f';
}
.zmdi-fast-rewind:before {
  content: '\f3a0';
}
.zmdi-forward-10:before {
  content: '\f3a1';
}
.zmdi-forward-30:before {
  content: '\f3a2';
}
.zmdi-forward-5:before {
  content: '\f3a3';
}
.zmdi-hearing:before {
  content: '\f3a4';
}
.zmdi-pause-circle-outline:before {
  content: '\f3a5';
}
.zmdi-pause-circle:before {
  content: '\f3a6';
}
.zmdi-pause:before {
  content: '\f3a7';
}
.zmdi-play-circle-outline:before {
  content: '\f3a8';
}
.zmdi-play-circle:before {
  content: '\f3a9';
}
.zmdi-play:before {
  content: '\f3aa';
}
.zmdi-playlist-audio:before {
  content: '\f3ab';
}
.zmdi-playlist-plus:before {
  content: '\f3ac';
}
.zmdi-repeat-one:before {
  content: '\f3ad';
}
.zmdi-repeat:before {
  content: '\f3ae';
}
.zmdi-replay-10:before {
  content: '\f3af';
}
.zmdi-replay-30:before {
  content: '\f3b0';
}
.zmdi-replay-5:before {
  content: '\f3b1';
}
.zmdi-replay:before {
  content: '\f3b2';
}
.zmdi-shuffle:before {
  content: '\f3b3';
}
.zmdi-skip-next:before {
  content: '\f3b4';
}
.zmdi-skip-previous:before {
  content: '\f3b5';
}
.zmdi-stop:before {
  content: '\f3b6';
}
.zmdi-surround-sound:before {
  content: '\f3b7';
}
.zmdi-tune:before {
  content: '\f3b8';
}
.zmdi-volume-down:before {
  content: '\f3b9';
}
.zmdi-volume-mute:before {
  content: '\f3ba';
}
.zmdi-volume-off:before {
  content: '\f3bb';
}
.zmdi-volume-up:before {
  content: '\f3bc';
}
.zmdi-n-1-square:before {
  content: '\f3bd';
}
.zmdi-n-2-square:before {
  content: '\f3be';
}
.zmdi-n-3-square:before {
  content: '\f3bf';
}
.zmdi-n-4-square:before {
  content: '\f3c0';
}
.zmdi-n-5-square:before {
  content: '\f3c1';
}
.zmdi-n-6-square:before {
  content: '\f3c2';
}
.zmdi-neg-1:before {
  content: '\f3c3';
}
.zmdi-neg-2:before {
  content: '\f3c4';
}
.zmdi-plus-1:before {
  content: '\f3c5';
}
.zmdi-plus-2:before {
  content: '\f3c6';
}
.zmdi-sec-10:before {
  content: '\f3c7';
}
.zmdi-sec-3:before {
  content: '\f3c8';
}
.zmdi-zero:before {
  content: '\f3c9';
}
.zmdi-airline-seat-flat-angled:before {
  content: '\f3ca';
}
.zmdi-airline-seat-flat:before {
  content: '\f3cb';
}
.zmdi-airline-seat-individual-suite:before {
  content: '\f3cc';
}
.zmdi-airline-seat-legroom-extra:before {
  content: '\f3cd';
}
.zmdi-airline-seat-legroom-normal:before {
  content: '\f3ce';
}
.zmdi-airline-seat-legroom-reduced:before {
  content: '\f3cf';
}
.zmdi-airline-seat-recline-extra:before {
  content: '\f3d0';
}
.zmdi-airline-seat-recline-normal:before {
  content: '\f3d1';
}
.zmdi-airplay:before {
  content: '\f3d2';
}
.zmdi-closed-caption:before {
  content: '\f3d3';
}
.zmdi-confirmation-number:before {
  content: '\f3d4';
}
.zmdi-developer-board:before {
  content: '\f3d5';
}
.zmdi-disc-full:before {
  content: '\f3d6';
}
.zmdi-explicit:before {
  content: '\f3d7';
}
.zmdi-flight-land:before {
  content: '\f3d8';
}
.zmdi-flight-takeoff:before {
  content: '\f3d9';
}
.zmdi-flip-to-back:before {
  content: '\f3da';
}
.zmdi-flip-to-front:before {
  content: '\f3db';
}
.zmdi-group-work:before {
  content: '\f3dc';
}
.zmdi-hd:before {
  content: '\f3dd';
}
.zmdi-hq:before {
  content: '\f3de';
}
.zmdi-markunread-mailbox:before {
  content: '\f3df';
}
.zmdi-memory:before {
  content: '\f3e0';
}
.zmdi-nfc:before {
  content: '\f3e1';
}
.zmdi-play-for-work:before {
  content: '\f3e2';
}
.zmdi-power-input:before {
  content: '\f3e3';
}
.zmdi-present-to-all:before {
  content: '\f3e4';
}
.zmdi-satellite:before {
  content: '\f3e5';
}
.zmdi-tap-and-play:before {
  content: '\f3e6';
}
.zmdi-vibration:before {
  content: '\f3e7';
}
.zmdi-voicemail:before {
  content: '\f3e8';
}
.zmdi-group:before {
  content: '\f3e9';
}
.zmdi-rss:before {
  content: '\f3ea';
}
.zmdi-shape:before {
  content: '\f3eb';
}
.zmdi-spinner:before {
  content: '\f3ec';
}
.zmdi-ungroup:before {
  content: '\f3ed';
}
.zmdi-500px:before {
  content: '\f3ee';
}
.zmdi-8tracks:before {
  content: '\f3ef';
}
.zmdi-amazon:before {
  content: '\f3f0';
}
.zmdi-blogger:before {
  content: '\f3f1';
}
.zmdi-delicious:before {
  content: '\f3f2';
}
.zmdi-disqus:before {
  content: '\f3f3';
}
.zmdi-flattr:before {
  content: '\f3f4';
}
.zmdi-flickr:before {
  content: '\f3f5';
}
.zmdi-github-alt:before {
  content: '\f3f6';
}
.zmdi-google-old:before {
  content: '\f3f7';
}
.zmdi-linkedin:before {
  content: '\f3f8';
}
.zmdi-odnoklassniki:before {
  content: '\f3f9';
}
.zmdi-outlook:before {
  content: '\f3fa';
}
.zmdi-paypal-alt:before {
  content: '\f3fb';
}
.zmdi-pinterest:before {
  content: '\f3fc';
}
.zmdi-playstation:before {
  content: '\f3fd';
}
.zmdi-reddit:before {
  content: '\f3fe';
}
.zmdi-skype:before {
  content: '\f3ff';
}
.zmdi-slideshare:before {
  content: '\f400';
}
.zmdi-soundcloud:before {
  content: '\f401';
}
.zmdi-tumblr:before {
  content: '\f402';
}
.zmdi-twitch:before {
  content: '\f403';
}
.zmdi-vimeo:before {
  content: '\f404';
}
.zmdi-whatsapp:before {
  content: '\f405';
}
.zmdi-xbox:before {
  content: '\f406';
}
.zmdi-yahoo:before {
  content: '\f407';
}
.zmdi-youtube-play:before {
  content: '\f408';
}
.zmdi-youtube:before {
  content: '\f409';
}
.zmdi-3d-rotation:before {
  content: '\f101';
}
.zmdi-airplane-off:before {
  content: '\f102';
}
.zmdi-airplane:before {
  content: '\f103';
}
.zmdi-album:before {
  content: '\f104';
}
.zmdi-archive:before {
  content: '\f105';
}
.zmdi-assignment-account:before {
  content: '\f106';
}
.zmdi-assignment-alert:before {
  content: '\f107';
}
.zmdi-assignment-check:before {
  content: '\f108';
}
.zmdi-assignment-o:before {
  content: '\f109';
}
.zmdi-assignment-return:before {
  content: '\f10a';
}
.zmdi-assignment-returned:before {
  content: '\f10b';
}
.zmdi-assignment:before {
  content: '\f10c';
}
.zmdi-attachment-alt:before {
  content: '\f10d';
}
.zmdi-attachment:before {
  content: '\f10e';
}
.zmdi-audio:before {
  content: '\f10f';
}
.zmdi-badge-check:before {
  content: '\f110';
}
.zmdi-balance-wallet:before {
  content: '\f111';
}
.zmdi-balance:before {
  content: '\f112';
}
.zmdi-battery-alert:before {
  content: '\f113';
}
.zmdi-battery-flash:before {
  content: '\f114';
}
.zmdi-battery-unknown:before {
  content: '\f115';
}
.zmdi-battery:before {
  content: '\f116';
}
.zmdi-bike:before {
  content: '\f117';
}
.zmdi-block-alt:before {
  content: '\f118';
}
.zmdi-block:before {
  content: '\f119';
}
.zmdi-boat:before {
  content: '\f11a';
}
.zmdi-book-image:before {
  content: '\f11b';
}
.zmdi-book:before {
  content: '\f11c';
}
.zmdi-bookmark-outline:before {
  content: '\f11d';
}
.zmdi-bookmark:before {
  content: '\f11e';
}
.zmdi-brush:before {
  content: '\f11f';
}
.zmdi-bug:before {
  content: '\f120';
}
.zmdi-bus:before {
  content: '\f121';
}
.zmdi-cake:before {
  content: '\f122';
}
.zmdi-car-taxi:before {
  content: '\f123';
}
.zmdi-car-wash:before {
  content: '\f124';
}
.zmdi-car:before {
  content: '\f125';
}
.zmdi-card-giftcard:before {
  content: '\f126';
}
.zmdi-card-membership:before {
  content: '\f127';
}
.zmdi-card-travel:before {
  content: '\f128';
}
.zmdi-card:before {
  content: '\f129';
}
.zmdi-case-check:before {
  content: '\f12a';
}
.zmdi-case-download:before {
  content: '\f12b';
}
.zmdi-case-play:before {
  content: '\f12c';
}
.zmdi-case:before {
  content: '\f12d';
}
.zmdi-cast-connected:before {
  content: '\f12e';
}
.zmdi-cast:before {
  content: '\f12f';
}
.zmdi-chart-donut:before {
  content: '\f130';
}
.zmdi-chart:before {
  content: '\f131';
}
.zmdi-city-alt:before {
  content: '\f132';
}
.zmdi-city:before {
  content: '\f133';
}
.zmdi-close-circle-o:before {
  content: '\f134';
}
.zmdi-close-circle:before {
  content: '\f135';
}
.zmdi-close:before {
  content: '\f136';
}
.zmdi-cocktail:before {
  content: '\f137';
}
.zmdi-code-setting:before {
  content: '\f138';
}
.zmdi-code-smartphone:before {
  content: '\f139';
}
.zmdi-code:before {
  content: '\f13a';
}
.zmdi-coffee:before {
  content: '\f13b';
}
.zmdi-collection-bookmark:before {
  content: '\f13c';
}
.zmdi-collection-case-play:before {
  content: '\f13d';
}
.zmdi-collection-folder-image:before {
  content: '\f13e';
}
.zmdi-collection-image-o:before {
  content: '\f13f';
}
.zmdi-collection-image:before {
  content: '\f140';
}
.zmdi-collection-item-1:before {
  content: '\f141';
}
.zmdi-collection-item-2:before {
  content: '\f142';
}
.zmdi-collection-item-3:before {
  content: '\f143';
}
.zmdi-collection-item-4:before {
  content: '\f144';
}
.zmdi-collection-item-5:before {
  content: '\f145';
}
.zmdi-collection-item-6:before {
  content: '\f146';
}
.zmdi-collection-item-7:before {
  content: '\f147';
}
.zmdi-collection-item-8:before {
  content: '\f148';
}
.zmdi-collection-item-9-plus:before {
  content: '\f149';
}
.zmdi-collection-item-9:before {
  content: '\f14a';
}
.zmdi-collection-item:before {
  content: '\f14b';
}
.zmdi-collection-music:before {
  content: '\f14c';
}
.zmdi-collection-pdf:before {
  content: '\f14d';
}
.zmdi-collection-plus:before {
  content: '\f14e';
}
.zmdi-collection-speaker:before {
  content: '\f14f';
}
.zmdi-collection-text:before {
  content: '\f150';
}
.zmdi-collection-video:before {
  content: '\f151';
}
.zmdi-compass:before {
  content: '\f152';
}
.zmdi-cutlery:before {
  content: '\f153';
}
.zmdi-delete:before {
  content: '\f154';
}
.zmdi-dialpad:before {
  content: '\f155';
}
.zmdi-dns:before {
  content: '\f156';
}
.zmdi-drink:before {
  content: '\f157';
}
.zmdi-edit:before {
  content: '\f158';
}
.zmdi-email-open:before {
  content: '\f159';
}
.zmdi-email:before {
  content: '\f15a';
}
.zmdi-eye-off:before {
  content: '\f15b';
}
.zmdi-eye:before {
  content: '\f15c';
}
.zmdi-eyedropper:before {
  content: '\f15d';
}
.zmdi-favorite-outline:before {
  content: '\f15e';
}
.zmdi-favorite:before {
  content: '\f15f';
}
.zmdi-filter-list:before {
  content: '\f160';
}
.zmdi-fire:before {
  content: '\f161';
}
.zmdi-flag:before {
  content: '\f162';
}
.zmdi-flare:before {
  content: '\f163';
}
.zmdi-flash-auto:before {
  content: '\f164';
}
.zmdi-flash-off:before {
  content: '\f165';
}
.zmdi-flash:before {
  content: '\f166';
}
.zmdi-flip:before {
  content: '\f167';
}
.zmdi-flower-alt:before {
  content: '\f168';
}
.zmdi-flower:before {
  content: '\f169';
}
.zmdi-font:before {
  content: '\f16a';
}
.zmdi-fullscreen-alt:before {
  content: '\f16b';
}
.zmdi-fullscreen-exit:before {
  content: '\f16c';
}
.zmdi-fullscreen:before {
  content: '\f16d';
}
.zmdi-functions:before {
  content: '\f16e';
}
.zmdi-gas-station:before {
  content: '\f16f';
}
.zmdi-gesture:before {
  content: '\f170';
}
.zmdi-globe-alt:before {
  content: '\f171';
}
.zmdi-globe-lock:before {
  content: '\f172';
}
.zmdi-globe:before {
  content: '\f173';
}
.zmdi-graduation-cap:before {
  content: '\f174';
}
.zmdi-home:before {
  content: '\f175';
}
.zmdi-hospital-alt:before {
  content: '\f176';
}
.zmdi-hospital:before {
  content: '\f177';
}
.zmdi-hotel:before {
  content: '\f178';
}
.zmdi-hourglass-alt:before {
  content: '\f179';
}
.zmdi-hourglass-outline:before {
  content: '\f17a';
}
.zmdi-hourglass:before {
  content: '\f17b';
}
.zmdi-http:before {
  content: '\f17c';
}
.zmdi-image-alt:before {
  content: '\f17d';
}
.zmdi-image-o:before {
  content: '\f17e';
}
.zmdi-image:before {
  content: '\f17f';
}
.zmdi-inbox:before {
  content: '\f180';
}
.zmdi-invert-colors-off:before {
  content: '\f181';
}
.zmdi-invert-colors:before {
  content: '\f182';
}
.zmdi-key:before {
  content: '\f183';
}
.zmdi-label-alt-outline:before {
  content: '\f184';
}
.zmdi-label-alt:before {
  content: '\f185';
}
.zmdi-label-heart:before {
  content: '\f186';
}
.zmdi-label:before {
  content: '\f187';
}
.zmdi-labels:before {
  content: '\f188';
}
.zmdi-lamp:before {
  content: '\f189';
}
.zmdi-landscape:before {
  content: '\f18a';
}
.zmdi-layers-off:before {
  content: '\f18b';
}
.zmdi-layers:before {
  content: '\f18c';
}
.zmdi-library:before {
  content: '\f18d';
}
.zmdi-link:before {
  content: '\f18e';
}
.zmdi-lock-open:before {
  content: '\f18f';
}
.zmdi-lock-outline:before {
  content: '\f190';
}
.zmdi-lock:before {
  content: '\f191';
}
.zmdi-mail-reply-all:before {
  content: '\f192';
}
.zmdi-mail-reply:before {
  content: '\f193';
}
.zmdi-mail-send:before {
  content: '\f194';
}
.zmdi-mall:before {
  content: '\f195';
}
.zmdi-map:before {
  content: '\f196';
}
.zmdi-menu:before {
  content: '\f197';
}
.zmdi-money-box:before {
  content: '\f198';
}
.zmdi-money-off:before {
  content: '\f199';
}
.zmdi-money:before {
  content: '\f19a';
}
.zmdi-more-vert:before {
  content: '\f19b';
}
.zmdi-more:before {
  content: '\f19c';
}
.zmdi-movie-alt:before {
  content: '\f19d';
}
.zmdi-movie:before {
  content: '\f19e';
}
.zmdi-nature-people:before {
  content: '\f19f';
}
.zmdi-nature:before {
  content: '\f1a0';
}
.zmdi-navigation:before {
  content: '\f1a1';
}
.zmdi-open-in-browser:before {
  content: '\f1a2';
}
.zmdi-open-in-new:before {
  content: '\f1a3';
}
.zmdi-palette:before {
  content: '\f1a4';
}
.zmdi-parking:before {
  content: '\f1a5';
}
.zmdi-pin-account:before {
  content: '\f1a6';
}
.zmdi-pin-assistant:before {
  content: '\f1a7';
}
.zmdi-pin-drop:before {
  content: '\f1a8';
}
.zmdi-pin-help:before {
  content: '\f1a9';
}
.zmdi-pin-off:before {
  content: '\f1aa';
}
.zmdi-pin:before {
  content: '\f1ab';
}
.zmdi-pizza:before {
  content: '\f1ac';
}
.zmdi-plaster:before {
  content: '\f1ad';
}
.zmdi-power-setting:before {
  content: '\f1ae';
}
.zmdi-power:before {
  content: '\f1af';
}
.zmdi-print:before {
  content: '\f1b0';
}
.zmdi-puzzle-piece:before {
  content: '\f1b1';
}
.zmdi-quote:before {
  content: '\f1b2';
}
.zmdi-railway:before {
  content: '\f1b3';
}
.zmdi-receipt:before {
  content: '\f1b4';
}
.zmdi-refresh-alt:before {
  content: '\f1b5';
}
.zmdi-refresh-sync-alert:before {
  content: '\f1b6';
}
.zmdi-refresh-sync-off:before {
  content: '\f1b7';
}
.zmdi-refresh-sync:before {
  content: '\f1b8';
}
.zmdi-refresh:before {
  content: '\f1b9';
}
.zmdi-roller:before {
  content: '\f1ba';
}
.zmdi-ruler:before {
  content: '\f1bb';
}
.zmdi-scissors:before {
  content: '\f1bc';
}
.zmdi-screen-rotation-lock:before {
  content: '\f1bd';
}
.zmdi-screen-rotation:before {
  content: '\f1be';
}
.zmdi-search-for:before {
  content: '\f1bf';
}
.zmdi-search-in-file:before {
  content: '\f1c0';
}
.zmdi-search-in-page:before {
  content: '\f1c1';
}
.zmdi-search-replace:before {
  content: '\f1c2';
}
.zmdi-search:before {
  content: '\f1c3';
}
.zmdi-seat:before {
  content: '\f1c4';
}
.zmdi-settings-square:before {
  content: '\f1c5';
}
.zmdi-settings:before {
  content: '\f1c6';
}
.zmdi-shield-check:before {
  content: '\f1c7';
}
.zmdi-shield-security:before {
  content: '\f1c8';
}
.zmdi-shopping-basket:before {
  content: '\f1c9';
}
.zmdi-shopping-cart-plus:before {
  content: '\f1ca';
}
.zmdi-shopping-cart:before {
  content: '\f1cb';
}
.zmdi-sign-in:before {
  content: '\f1cc';
}
.zmdi-sort-amount-asc:before {
  content: '\f1cd';
}
.zmdi-sort-amount-desc:before {
  content: '\f1ce';
}
.zmdi-sort-asc:before {
  content: '\f1cf';
}
.zmdi-sort-desc:before {
  content: '\f1d0';
}
.zmdi-spellcheck:before {
  content: '\f1d1';
}
.zmdi-storage:before {
  content: '\f1d2';
}
.zmdi-store-24:before {
  content: '\f1d3';
}
.zmdi-store:before {
  content: '\f1d4';
}
.zmdi-subway:before {
  content: '\f1d5';
}
.zmdi-sun:before {
  content: '\f1d6';
}
.zmdi-tab-unselected:before {
  content: '\f1d7';
}
.zmdi-tab:before {
  content: '\f1d8';
}
.zmdi-tag-close:before {
  content: '\f1d9';
}
.zmdi-tag-more:before {
  content: '\f1da';
}
.zmdi-tag:before {
  content: '\f1db';
}
.zmdi-thumb-down:before {
  content: '\f1dc';
}
.zmdi-thumb-up-down:before {
  content: '\f1dd';
}
.zmdi-thumb-up:before {
  content: '\f1de';
}
.zmdi-ticket-star:before {
  content: '\f1df';
}
.zmdi-toll:before {
  content: '\f1e0';
}
.zmdi-toys:before {
  content: '\f1e1';
}
.zmdi-traffic:before {
  content: '\f1e2';
}
.zmdi-translate:before {
  content: '\f1e3';
}
.zmdi-triangle-down:before {
  content: '\f1e4';
}
.zmdi-triangle-up:before {
  content: '\f1e5';
}
.zmdi-truck:before {
  content: '\f1e6';
}
.zmdi-turning-sign:before {
  content: '\f1e7';
}
.zmdi-wallpaper:before {
  content: '\f1e8';
}
.zmdi-washing-machine:before {
  content: '\f1e9';
}
.zmdi-window-maximize:before {
  content: '\f1ea';
}
.zmdi-window-minimize:before {
  content: '\f1eb';
}
.zmdi-window-restore:before {
  content: '\f1ec';
}
.zmdi-wrench:before {
  content: '\f1ed';
}
.zmdi-zoom-in:before {
  content: '\f1ee';
}
.zmdi-zoom-out:before {
  content: '\f1ef';
}
.zmdi-alert-circle-o:before {
  content: '\f1f0';
}
.zmdi-alert-circle:before {
  content: '\f1f1';
}
.zmdi-alert-octagon:before {
  content: '\f1f2';
}
.zmdi-alert-polygon:before {
  content: '\f1f3';
}
.zmdi-alert-triangle:before {
  content: '\f1f4';
}
.zmdi-help-outline:before {
  content: '\f1f5';
}
.zmdi-help:before {
  content: '\f1f6';
}
.zmdi-info-outline:before {
  content: '\f1f7';
}
.zmdi-info:before {
  content: '\f1f8';
}
.zmdi-notifications-active:before {
  content: '\f1f9';
}
.zmdi-notifications-add:before {
  content: '\f1fa';
}
.zmdi-notifications-none:before {
  content: '\f1fb';
}
.zmdi-notifications-off:before {
  content: '\f1fc';
}
.zmdi-notifications-paused:before {
  content: '\f1fd';
}
.zmdi-notifications:before {
  content: '\f1fe';
}
.zmdi-account-add:before {
  content: '\f1ff';
}
.zmdi-account-box-mail:before {
  content: '\f200';
}
.zmdi-account-box-o:before {
  content: '\f201';
}
.zmdi-account-box-phone:before {
  content: '\f202';
}
.zmdi-account-box:before {
  content: '\f203';
}
.zmdi-account-calendar:before {
  content: '\f204';
}
.zmdi-account-circle:before {
  content: '\f205';
}
.zmdi-account-o:before {
  content: '\f206';
}
.zmdi-account:before {
  content: '\f207';
}
.zmdi-accounts-add:before {
  content: '\f208';
}
.zmdi-accounts-alt:before {
  content: '\f209';
}
.zmdi-accounts-list-alt:before {
  content: '\f20a';
}
.zmdi-accounts-list:before {
  content: '\f20b';
}
.zmdi-accounts-outline:before {
  content: '\f20c';
}
.zmdi-accounts:before {
  content: '\f20d';
}
.zmdi-face:before {
  content: '\f20e';
}
.zmdi-female:before {
  content: '\f20f';
}
.zmdi-male-alt:before {
  content: '\f210';
}
.zmdi-male-female:before {
  content: '\f211';
}
.zmdi-male:before {
  content: '\f212';
}
.zmdi-mood-bad:before {
  content: '\f213';
}
.zmdi-mood:before {
  content: '\f214';
}
.zmdi-run:before {
  content: '\f215';
}
.zmdi-walk:before {
  content: '\f216';
}
.zmdi-cloud-box:before {
  content: '\f217';
}
.zmdi-cloud-circle:before {
  content: '\f218';
}
.zmdi-cloud-done:before {
  content: '\f219';
}
.zmdi-cloud-download:before {
  content: '\f21a';
}
.zmdi-cloud-off:before {
  content: '\f21b';
}
.zmdi-cloud-outline-alt:before {
  content: '\f21c';
}
.zmdi-cloud-outline:before {
  content: '\f21d';
}
.zmdi-cloud-upload:before {
  content: '\f21e';
}
.zmdi-cloud:before {
  content: '\f21f';
}
.zmdi-download:before {
  content: '\f220';
}
.zmdi-file-plus:before {
  content: '\f221';
}
.zmdi-file-text:before {
  content: '\f222';
}
.zmdi-file:before {
  content: '\f223';
}
.zmdi-folder-outline:before {
  content: '\f224';
}
.zmdi-folder-person:before {
  content: '\f225';
}
.zmdi-folder-star-alt:before {
  content: '\f226';
}
.zmdi-folder-star:before {
  content: '\f227';
}
.zmdi-folder:before {
  content: '\f228';
}
.zmdi-gif:before {
  content: '\f229';
}
.zmdi-upload:before {
  content: '\f22a';
}
.zmdi-border-all:before {
  content: '\f22b';
}
.zmdi-border-bottom:before {
  content: '\f22c';
}
.zmdi-border-clear:before {
  content: '\f22d';
}
.zmdi-border-color:before {
  content: '\f22e';
}
.zmdi-border-horizontal:before {
  content: '\f22f';
}
.zmdi-border-inner:before {
  content: '\f230';
}
.zmdi-border-left:before {
  content: '\f231';
}
.zmdi-border-outer:before {
  content: '\f232';
}
.zmdi-border-right:before {
  content: '\f233';
}
.zmdi-border-style:before {
  content: '\f234';
}
.zmdi-border-top:before {
  content: '\f235';
}
.zmdi-border-vertical:before {
  content: '\f236';
}
.zmdi-copy:before {
  content: '\f237';
}
.zmdi-crop:before {
  content: '\f238';
}
.zmdi-format-align-center:before {
  content: '\f239';
}
.zmdi-format-align-justify:before {
  content: '\f23a';
}
.zmdi-format-align-left:before {
  content: '\f23b';
}
.zmdi-format-align-right:before {
  content: '\f23c';
}
.zmdi-format-bold:before {
  content: '\f23d';
}
.zmdi-format-clear-all:before {
  content: '\f23e';
}
.zmdi-format-clear:before {
  content: '\f23f';
}
.zmdi-format-color-fill:before {
  content: '\f240';
}
.zmdi-format-color-reset:before {
  content: '\f241';
}
.zmdi-format-color-text:before {
  content: '\f242';
}
.zmdi-format-indent-decrease:before {
  content: '\f243';
}
.zmdi-format-indent-increase:before {
  content: '\f244';
}
.zmdi-format-italic:before {
  content: '\f245';
}
.zmdi-format-line-spacing:before {
  content: '\f246';
}
.zmdi-format-list-bulleted:before {
  content: '\f247';
}
.zmdi-format-list-numbered:before {
  content: '\f248';
}
.zmdi-format-ltr:before {
  content: '\f249';
}
.zmdi-format-rtl:before {
  content: '\f24a';
}
.zmdi-format-size:before {
  content: '\f24b';
}
.zmdi-format-strikethrough-s:before {
  content: '\f24c';
}
.zmdi-format-strikethrough:before {
  content: '\f24d';
}
.zmdi-format-subject:before {
  content: '\f24e';
}
.zmdi-format-underlined:before {
  content: '\f24f';
}
.zmdi-format-valign-bottom:before {
  content: '\f250';
}
.zmdi-format-valign-center:before {
  content: '\f251';
}
.zmdi-format-valign-top:before {
  content: '\f252';
}
.zmdi-redo:before {
  content: '\f253';
}
.zmdi-select-all:before {
  content: '\f254';
}
.zmdi-space-bar:before {
  content: '\f255';
}
.zmdi-text-format:before {
  content: '\f256';
}
.zmdi-transform:before {
  content: '\f257';
}
.zmdi-undo:before {
  content: '\f258';
}
.zmdi-wrap-text:before {
  content: '\f259';
}
.zmdi-comment-alert:before {
  content: '\f25a';
}
.zmdi-comment-alt-text:before {
  content: '\f25b';
}
.zmdi-comment-alt:before {
  content: '\f25c';
}
.zmdi-comment-edit:before {
  content: '\f25d';
}
.zmdi-comment-image:before {
  content: '\f25e';
}
.zmdi-comment-list:before {
  content: '\f25f';
}
.zmdi-comment-more:before {
  content: '\f260';
}
.zmdi-comment-outline:before {
  content: '\f261';
}
.zmdi-comment-text-alt:before {
  content: '\f262';
}
.zmdi-comment-text:before {
  content: '\f263';
}
.zmdi-comment-video:before {
  content: '\f264';
}
.zmdi-comment:before {
  content: '\f265';
}
.zmdi-comments:before {
  content: '\f266';
}
.zmdi-check-all:before {
  content: '\f267';
}
.zmdi-check-circle-u:before {
  content: '\f268';
}
.zmdi-check-circle:before {
  content: '\f269';
}
.zmdi-check-square:before {
  content: '\f26a';
}
.zmdi-check:before {
  content: '\f26b';
}
.zmdi-circle-o:before {
  content: '\f26c';
}
.zmdi-circle:before {
  content: '\f26d';
}
.zmdi-dot-circle-alt:before {
  content: '\f26e';
}
.zmdi-dot-circle:before {
  content: '\f26f';
}
.zmdi-minus-circle-outline:before {
  content: '\f270';
}
.zmdi-minus-circle:before {
  content: '\f271';
}
.zmdi-minus-square:before {
  content: '\f272';
}
.zmdi-minus:before {
  content: '\f273';
}
.zmdi-plus-circle-o-duplicate:before {
  content: '\f274';
}
.zmdi-plus-circle-o:before {
  content: '\f275';
}
.zmdi-plus-circle:before {
  content: '\f276';
}
.zmdi-plus-square:before {
  content: '\f277';
}
.zmdi-plus:before {
  content: '\f278';
}
.zmdi-square-o:before {
  content: '\f279';
}
.zmdi-star-circle:before {
  content: '\f27a';
}
.zmdi-star-half:before {
  content: '\f27b';
}
.zmdi-star-outline:before {
  content: '\f27c';
}
.zmdi-star:before {
  content: '\f27d';
}
.zmdi-bluetooth-connected:before {
  content: '\f27e';
}
.zmdi-bluetooth-off:before {
  content: '\f27f';
}
.zmdi-bluetooth-search:before {
  content: '\f280';
}
.zmdi-bluetooth-setting:before {
  content: '\f281';
}
.zmdi-bluetooth:before {
  content: '\f282';
}
.zmdi-camera-add:before {
  content: '\f283';
}
.zmdi-camera-alt:before {
  content: '\f284';
}
.zmdi-camera-bw:before {
  content: '\f285';
}
.zmdi-camera-front:before {
  content: '\f286';
}
.zmdi-camera-mic:before {
  content: '\f287';
}
.zmdi-camera-party-mode:before {
  content: '\f288';
}
.zmdi-camera-rear:before {
  content: '\f289';
}
.zmdi-camera-roll:before {
  content: '\f28a';
}
.zmdi-camera-switch:before {
  content: '\f28b';
}
.zmdi-camera:before {
  content: '\f28c';
}
.zmdi-card-alert:before {
  content: '\f28d';
}
.zmdi-card-off:before {
  content: '\f28e';
}
.zmdi-card-sd:before {
  content: '\f28f';
}
.zmdi-card-sim:before {
  content: '\f290';
}
.zmdi-desktop-mac:before {
  content: '\f291';
}
.zmdi-desktop-windows:before {
  content: '\f292';
}
.zmdi-device-hub:before {
  content: '\f293';
}
.zmdi-devices-off:before {
  content: '\f294';
}
.zmdi-devices:before {
  content: '\f295';
}
.zmdi-dock:before {
  content: '\f296';
}
.zmdi-floppy:before {
  content: '\f297';
}
.zmdi-gamepad:before {
  content: '\f298';
}
.zmdi-gps-dot:before {
  content: '\f299';
}
.zmdi-gps-off:before {
  content: '\f29a';
}
.zmdi-gps:before {
  content: '\f29b';
}
.zmdi-headset-mic:before {
  content: '\f29c';
}
.zmdi-headset:before {
  content: '\f29d';
}
.zmdi-input-antenna:before {
  content: '\f29e';
}
.zmdi-input-composite:before {
  content: '\f29f';
}
.zmdi-input-hdmi:before {
  content: '\f2a0';
}
.zmdi-input-power:before {
  content: '\f2a1';
}
.zmdi-input-svideo:before {
  content: '\f2a2';
}
.zmdi-keyboard-hide:before {
  content: '\f2a3';
}
.zmdi-keyboard:before {
  content: '\f2a4';
}
.zmdi-laptop-chromebook:before {
  content: '\f2a5';
}
.zmdi-laptop-mac:before {
  content: '\f2a6';
}
.zmdi-laptop:before {
  content: '\f2a7';
}
.zmdi-mic-off:before {
  content: '\f2a8';
}
.zmdi-mic-outline:before {
  content: '\f2a9';
}
.zmdi-mic-setting:before {
  content: '\f2aa';
}
.zmdi-mic:before {
  content: '\f2ab';
}
.zmdi-mouse:before {
  content: '\f2ac';
}
.zmdi-network-alert:before {
  content: '\f2ad';
}
.zmdi-network-locked:before {
  content: '\f2ae';
}
.zmdi-network-off:before {
  content: '\f2af';
}
.zmdi-network-outline:before {
  content: '\f2b0';
}
.zmdi-network-setting:before {
  content: '\f2b1';
}
.zmdi-network:before {
  content: '\f2b2';
}
.zmdi-phone-bluetooth:before {
  content: '\f2b3';
}
.zmdi-phone-end:before {
  content: '\f2b4';
}
.zmdi-phone-forwarded:before {
  content: '\f2b5';
}
.zmdi-phone-in-talk:before {
  content: '\f2b6';
}
.zmdi-phone-locked:before {
  content: '\f2b7';
}
.zmdi-phone-missed:before {
  content: '\f2b8';
}
.zmdi-phone-msg:before {
  content: '\f2b9';
}
.zmdi-phone-paused:before {
  content: '\f2ba';
}
.zmdi-phone-ring:before {
  content: '\f2bb';
}
.zmdi-phone-setting:before {
  content: '\f2bc';
}
.zmdi-phone-sip:before {
  content: '\f2bd';
}
.zmdi-phone:before {
  content: '\f2be';
}
.zmdi-portable-wifi-changes:before {
  content: '\f2bf';
}
.zmdi-portable-wifi-off:before {
  content: '\f2c0';
}
.zmdi-portable-wifi:before {
  content: '\f2c1';
}
.zmdi-radio:before {
  content: '\f2c2';
}
.zmdi-reader:before {
  content: '\f2c3';
}
.zmdi-remote-control-alt:before {
  content: '\f2c4';
}
.zmdi-remote-control:before {
  content: '\f2c5';
}
.zmdi-router:before {
  content: '\f2c6';
}
.zmdi-scanner:before {
  content: '\f2c7';
}
.zmdi-smartphone-android:before {
  content: '\f2c8';
}
.zmdi-smartphone-download:before {
  content: '\f2c9';
}
.zmdi-smartphone-erase:before {
  content: '\f2ca';
}
.zmdi-smartphone-info:before {
  content: '\f2cb';
}
.zmdi-smartphone-iphone:before {
  content: '\f2cc';
}
.zmdi-smartphone-landscape-lock:before {
  content: '\f2cd';
}
.zmdi-smartphone-landscape:before {
  content: '\f2ce';
}
.zmdi-smartphone-lock:before {
  content: '\f2cf';
}
.zmdi-smartphone-portrait-lock:before {
  content: '\f2d0';
}
.zmdi-smartphone-ring:before {
  content: '\f2d1';
}
.zmdi-smartphone-setting:before {
  content: '\f2d2';
}
.zmdi-smartphone-setup:before {
  content: '\f2d3';
}
.zmdi-smartphone:before {
  content: '\f2d4';
}
.zmdi-speaker:before {
  content: '\f2d5';
}
.zmdi-tablet-android:before {
  content: '\f2d6';
}
.zmdi-tablet-mac:before {
  content: '\f2d7';
}
.zmdi-tablet:before {
  content: '\f2d8';
}
.zmdi-tv-alt-play:before {
  content: '\f2d9';
}
.zmdi-tv-list:before {
  content: '\f2da';
}
.zmdi-tv-play:before {
  content: '\f2db';
}
.zmdi-tv:before {
  content: '\f2dc';
}
.zmdi-usb:before {
  content: '\f2dd';
}
.zmdi-videocam-off:before {
  content: '\f2de';
}
.zmdi-videocam-switch:before {
  content: '\f2df';
}
.zmdi-videocam:before {
  content: '\f2e0';
}
.zmdi-watch:before {
  content: '\f2e1';
}
.zmdi-wifi-alt-2:before {
  content: '\f2e2';
}
.zmdi-wifi-alt:before {
  content: '\f2e3';
}
.zmdi-wifi-info:before {
  content: '\f2e4';
}
.zmdi-wifi-lock:before {
  content: '\f2e5';
}
.zmdi-wifi-off:before {
  content: '\f2e6';
}
.zmdi-wifi-outline:before {
  content: '\f2e7';
}
.zmdi-wifi:before {
  content: '\f2e8';
}
.zmdi-arrow-left-bottom:before {
  content: '\f2e9';
}
.zmdi-arrow-left:before {
  content: '\f2ea';
}
.zmdi-arrow-merge:before {
  content: '\f2eb';
}
.zmdi-arrow-missed:before {
  content: '\f2ec';
}
.zmdi-arrow-right-top:before {
  content: '\f2ed';
}
.zmdi-arrow-right:before {
  content: '\f2ee';
}
.zmdi-arrow-split:before {
  content: '\f2ef';
}
.zmdi-arrows:before {
  content: '\f2f0';
}
.zmdi-caret-down-circle:before {
  content: '\f2f1';
}
.zmdi-caret-down:before {
  content: '\f2f2';
}
.zmdi-caret-left-circle:before {
  content: '\f2f3';
}
.zmdi-caret-left:before {
  content: '\f2f4';
}
.zmdi-caret-right-circle:before {
  content: '\f2f5';
}
.zmdi-caret-right:before {
  content: '\f2f6';
}
.zmdi-caret-up-circle:before {
  content: '\f2f7';
}
.zmdi-caret-up:before {
  content: '\f2f8';
}
.zmdi-chevron-down:before {
  content: '\f2f9';
}
.zmdi-chevron-left:before {
  content: '\f2fa';
}
.zmdi-chevron-right:before {
  content: '\f2fb';
}
.zmdi-chevron-up:before {
  content: '\f2fc';
}
.zmdi-forward:before {
  content: '\f2fd';
}
.zmdi-long-arrow-down:before {
  content: '\f2fe';
}
.zmdi-long-arrow-left:before {
  content: '\f2ff';
}
.zmdi-long-arrow-return:before {
  content: '\f300';
}
.zmdi-long-arrow-right:before {
  content: '\f301';
}
.zmdi-long-arrow-tab:before {
  content: '\f302';
}
.zmdi-long-arrow-up:before {
  content: '\f303';
}
.zmdi-rotate-ccw:before {
  content: '\f304';
}
.zmdi-rotate-cw:before {
  content: '\f305';
}
.zmdi-rotate-left:before {
  content: '\f306';
}
.zmdi-rotate-right:before {
  content: '\f307';
}
.zmdi-square-down:before {
  content: '\f308';
}
.zmdi-square-right:before {
  content: '\f309';
}
.zmdi-swap-alt:before {
  content: '\f30a';
}
.zmdi-swap-vertical-circle:before {
  content: '\f30b';
}
.zmdi-swap-vertical:before {
  content: '\f30c';
}
.zmdi-swap:before {
  content: '\f30d';
}
.zmdi-trending-down:before {
  content: '\f30e';
}
.zmdi-trending-flat:before {
  content: '\f30f';
}
.zmdi-trending-up:before {
  content: '\f310';
}
.zmdi-unfold-less:before {
  content: '\f311';
}
.zmdi-unfold-more:before {
  content: '\f312';
}
.zmdi-apps:before {
  content: '\f313';
}
.zmdi-grid-off:before {
  content: '\f314';
}
.zmdi-grid:before {
  content: '\f315';
}
.zmdi-view-agenda:before {
  content: '\f316';
}
.zmdi-view-array:before {
  content: '\f317';
}
.zmdi-view-carousel:before {
  content: '\f318';
}
.zmdi-view-column:before {
  content: '\f319';
}
.zmdi-view-comfy:before {
  content: '\f31a';
}
.zmdi-view-compact:before {
  content: '\f31b';
}
.zmdi-view-dashboard:before {
  content: '\f31c';
}
.zmdi-view-day:before {
  content: '\f31d';
}
.zmdi-view-headline:before {
  content: '\f31e';
}
.zmdi-view-list-alt:before {
  content: '\f31f';
}
.zmdi-view-list:before {
  content: '\f320';
}
.zmdi-view-module:before {
  content: '\f321';
}
.zmdi-view-quilt:before {
  content: '\f322';
}
.zmdi-view-stream:before {
  content: '\f323';
}
.zmdi-view-subtitles:before {
  content: '\f324';
}
.zmdi-view-toc:before {
  content: '\f325';
}
.zmdi-view-web:before {
  content: '\f326';
}
.zmdi-view-week:before {
  content: '\f327';
}
.zmdi-widgets:before {
  content: '\f328';
}
.zmdi-alarm-check:before {
  content: '\f329';
}
.zmdi-alarm-off:before {
  content: '\f32a';
}
.zmdi-alarm-plus:before {
  content: '\f32b';
}
.zmdi-alarm-snooze:before {
  content: '\f32c';
}
.zmdi-alarm:before {
  content: '\f32d';
}
.zmdi-calendar-alt:before {
  content: '\f32e';
}
.zmdi-calendar-check:before {
  content: '\f32f';
}
.zmdi-calendar-close:before {
  content: '\f330';
}
.zmdi-calendar-note:before {
  content: '\f331';
}
.zmdi-calendar:before {
  content: '\f332';
}
.zmdi-time-countdown:before {
  content: '\f333';
}
.zmdi-time-interval:before {
  content: '\f334';
}
.zmdi-time-restore-setting:before {
  content: '\f335';
}
.zmdi-time-restore:before {
  content: '\f336';
}
.zmdi-time:before {
  content: '\f337';
}
.zmdi-timer-off:before {
  content: '\f338';
}
.zmdi-timer:before {
  content: '\f339';
}
.zmdi-android-alt:before {
  content: '\f33a';
}
.zmdi-android:before {
  content: '\f33b';
}
.zmdi-apple:before {
  content: '\f33c';
}
.zmdi-behance:before {
  content: '\f33d';
}
.zmdi-codepen:before {
  content: '\f33e';
}
.zmdi-dribbble:before {
  content: '\f33f';
}
.zmdi-dropbox:before {
  content: '\f340';
}
.zmdi-evernote:before {
  content: '\f341';
}
.zmdi-facebook-box:before {
  content: '\f342';
}
.zmdi-facebook:before {
  content: '\f343';
}
.zmdi-github-box:before {
  content: '\f344';
}
.zmdi-github:before {
  content: '\f345';
}
.zmdi-google-drive:before {
  content: '\f346';
}
.zmdi-google-earth:before {
  content: '\f347';
}
.zmdi-google-glass:before {
  content: '\f348';
}
.zmdi-google-maps:before {
  content: '\f349';
}
.zmdi-google-pages:before {
  content: '\f34a';
}
.zmdi-google-play:before {
  content: '\f34b';
}
.zmdi-google-plus-box:before {
  content: '\f34c';
}
.zmdi-google-plus:before {
  content: '\f34d';
}
.zmdi-google:before {
  content: '\f34e';
}
.zmdi-instagram:before {
  content: '\f34f';
}
.zmdi-language-css3:before {
  content: '\f350';
}
.zmdi-language-html5:before {
  content: '\f351';
}
.zmdi-language-javascript:before {
  content: '\f352';
}
.zmdi-language-python-alt:before {
  content: '\f353';
}
.zmdi-language-python:before {
  content: '\f354';
}
.zmdi-lastfm:before {
  content: '\f355';
}
.zmdi-linkedin-box:before {
  content: '\f356';
}
.zmdi-paypal:before {
  content: '\f357';
}
.zmdi-pinterest-box:before {
  content: '\f358';
}
.zmdi-pocket:before {
  content: '\f359';
}
.zmdi-polymer:before {
  content: '\f35a';
}
.zmdi-share:before {
  content: '\f35b';
}
.zmdi-stackoverflow:before {
  content: '\f35c';
}
.zmdi-steam-square:before {
  content: '\f35d';
}
.zmdi-steam:before {
  content: '\f35e';
}
.zmdi-twitter-box:before {
  content: '\f35f';
}
.zmdi-twitter:before {
  content: '\f360';
}
.zmdi-vk:before {
  content: '\f361';
}
.zmdi-wikipedia:before {
  content: '\f362';
}
.zmdi-windows:before {
  content: '\f363';
}
.zmdi-aspect-ratio-alt:before {
  content: '\f364';
}
.zmdi-aspect-ratio:before {
  content: '\f365';
}
.zmdi-blur-circular:before {
  content: '\f366';
}
.zmdi-blur-linear:before {
  content: '\f367';
}
.zmdi-blur-off:before {
  content: '\f368';
}
.zmdi-blur:before {
  content: '\f369';
}
.zmdi-brightness-2:before {
  content: '\f36a';
}
.zmdi-brightness-3:before {
  content: '\f36b';
}
.zmdi-brightness-4:before {
  content: '\f36c';
}
.zmdi-brightness-5:before {
  content: '\f36d';
}
.zmdi-brightness-6:before {
  content: '\f36e';
}
.zmdi-brightness-7:before {
  content: '\f36f';
}
.zmdi-brightness-auto:before {
  content: '\f370';
}
.zmdi-brightness-setting:before {
  content: '\f371';
}
.zmdi-broken-image:before {
  content: '\f372';
}
.zmdi-center-focus-strong:before {
  content: '\f373';
}
.zmdi-center-focus-weak:before {
  content: '\f374';
}
.zmdi-compare:before {
  content: '\f375';
}
.zmdi-crop-16-9:before {
  content: '\f376';
}
.zmdi-crop-3-2:before {
  content: '\f377';
}
.zmdi-crop-5-4:before {
  content: '\f378';
}
.zmdi-crop-7-5:before {
  content: '\f379';
}
.zmdi-crop-din:before {
  content: '\f37a';
}
.zmdi-crop-free:before {
  content: '\f37b';
}
.zmdi-crop-landscape:before {
  content: '\f37c';
}
.zmdi-crop-portrait:before {
  content: '\f37d';
}
.zmdi-crop-square:before {
  content: '\f37e';
}
.zmdi-exposure-alt:before {
  content: '\f37f';
}
.zmdi-exposure:before {
  content: '\f380';
}
.zmdi-filter-b-and-w:before {
  content: '\f381';
}
.zmdi-filter-center-focus:before {
  content: '\f382';
}
.zmdi-filter-frames:before {
  content: '\f383';
}
.zmdi-filter-tilt-shift:before {
  content: '\f384';
}
.zmdi-gradient:before {
  content: '\f385';
}
.zmdi-grain:before {
  content: '\f386';
}
.zmdi-graphic-eq:before {
  content: '\f387';
}
.zmdi-hdr-off:before {
  content: '\f388';
}
.zmdi-hdr-strong:before {
  content: '\f389';
}
.zmdi-hdr-weak:before {
  content: '\f38a';
}
.zmdi-hdr:before {
  content: '\f38b';
}
.zmdi-iridescent:before {
  content: '\f38c';
}
.zmdi-leak-off:before {
  content: '\f38d';
}
.zmdi-leak:before {
  content: '\f38e';
}
.zmdi-looks:before {
  content: '\f38f';
}
.zmdi-loupe:before {
  content: '\f390';
}
.zmdi-panorama-horizontal:before {
  content: '\f391';
}
.zmdi-panorama-vertical:before {
  content: '\f392';
}
.zmdi-panorama-wide-angle:before {
  content: '\f393';
}
.zmdi-photo-size-select-large:before {
  content: '\f394';
}
.zmdi-photo-size-select-small:before {
  content: '\f395';
}
.zmdi-picture-in-picture:before {
  content: '\f396';
}
.zmdi-slideshow:before {
  content: '\f397';
}
.zmdi-texture:before {
  content: '\f398';
}
.zmdi-tonality:before {
  content: '\f399';
}
.zmdi-vignette:before {
  content: '\f39a';
}
.zmdi-wb-auto:before {
  content: '\f39b';
}
.zmdi-eject-alt:before {
  content: '\f39c';
}
.zmdi-eject:before {
  content: '\f39d';
}
.zmdi-equalizer:before {
  content: '\f39e';
}
.zmdi-fast-forward:before {
  content: '\f39f';
}
.zmdi-fast-rewind:before {
  content: '\f3a0';
}
.zmdi-forward-10:before {
  content: '\f3a1';
}
.zmdi-forward-30:before {
  content: '\f3a2';
}
.zmdi-forward-5:before {
  content: '\f3a3';
}
.zmdi-hearing:before {
  content: '\f3a4';
}
.zmdi-pause-circle-outline:before {
  content: '\f3a5';
}
.zmdi-pause-circle:before {
  content: '\f3a6';
}
.zmdi-pause:before {
  content: '\f3a7';
}
.zmdi-play-circle-outline:before {
  content: '\f3a8';
}
.zmdi-play-circle:before {
  content: '\f3a9';
}
.zmdi-play:before {
  content: '\f3aa';
}
.zmdi-playlist-audio:before {
  content: '\f3ab';
}
.zmdi-playlist-plus:before {
  content: '\f3ac';
}
.zmdi-repeat-one:before {
  content: '\f3ad';
}
.zmdi-repeat:before {
  content: '\f3ae';
}
.zmdi-replay-10:before {
  content: '\f3af';
}
.zmdi-replay-30:before {
  content: '\f3b0';
}
.zmdi-replay-5:before {
  content: '\f3b1';
}
.zmdi-replay:before {
  content: '\f3b2';
}
.zmdi-shuffle:before {
  content: '\f3b3';
}
.zmdi-skip-next:before {
  content: '\f3b4';
}
.zmdi-skip-previous:before {
  content: '\f3b5';
}
.zmdi-stop:before {
  content: '\f3b6';
}
.zmdi-surround-sound:before {
  content: '\f3b7';
}
.zmdi-tune:before {
  content: '\f3b8';
}
.zmdi-volume-down:before {
  content: '\f3b9';
}
.zmdi-volume-mute:before {
  content: '\f3ba';
}
.zmdi-volume-off:before {
  content: '\f3bb';
}
.zmdi-volume-up:before {
  content: '\f3bc';
}
.zmdi-n-1-square:before {
  content: '\f3bd';
}
.zmdi-n-2-square:before {
  content: '\f3be';
}
.zmdi-n-3-square:before {
  content: '\f3bf';
}
.zmdi-n-4-square:before {
  content: '\f3c0';
}
.zmdi-n-5-square:before {
  content: '\f3c1';
}
.zmdi-n-6-square:before {
  content: '\f3c2';
}
.zmdi-neg-1:before {
  content: '\f3c3';
}
.zmdi-neg-2:before {
  content: '\f3c4';
}
.zmdi-plus-1:before {
  content: '\f3c5';
}
.zmdi-plus-2:before {
  content: '\f3c6';
}
.zmdi-sec-10:before {
  content: '\f3c7';
}
.zmdi-sec-3:before {
  content: '\f3c8';
}
.zmdi-zero:before {
  content: '\f3c9';
}
.zmdi-airline-seat-flat-angled:before {
  content: '\f3ca';
}
.zmdi-airline-seat-flat:before {
  content: '\f3cb';
}
.zmdi-airline-seat-individual-suite:before {
  content: '\f3cc';
}
.zmdi-airline-seat-legroom-extra:before {
  content: '\f3cd';
}
.zmdi-airline-seat-legroom-normal:before {
  content: '\f3ce';
}
.zmdi-airline-seat-legroom-reduced:before {
  content: '\f3cf';
}
.zmdi-airline-seat-recline-extra:before {
  content: '\f3d0';
}
.zmdi-airline-seat-recline-normal:before {
  content: '\f3d1';
}
.zmdi-airplay:before {
  content: '\f3d2';
}
.zmdi-closed-caption:before {
  content: '\f3d3';
}
.zmdi-confirmation-number:before {
  content: '\f3d4';
}
.zmdi-developer-board:before {
  content: '\f3d5';
}
.zmdi-disc-full:before {
  content: '\f3d6';
}
.zmdi-explicit:before {
  content: '\f3d7';
}
.zmdi-flight-land:before {
  content: '\f3d8';
}
.zmdi-flight-takeoff:before {
  content: '\f3d9';
}
.zmdi-flip-to-back:before {
  content: '\f3da';
}
.zmdi-flip-to-front:before {
  content: '\f3db';
}
.zmdi-group-work:before {
  content: '\f3dc';
}
.zmdi-hd:before {
  content: '\f3dd';
}
.zmdi-hq:before {
  content: '\f3de';
}
.zmdi-markunread-mailbox:before {
  content: '\f3df';
}
.zmdi-memory:before {
  content: '\f3e0';
}
.zmdi-nfc:before {
  content: '\f3e1';
}
.zmdi-play-for-work:before {
  content: '\f3e2';
}
.zmdi-power-input:before {
  content: '\f3e3';
}
.zmdi-present-to-all:before {
  content: '\f3e4';
}
.zmdi-satellite:before {
  content: '\f3e5';
}
.zmdi-tap-and-play:before {
  content: '\f3e6';
}
.zmdi-vibration:before {
  content: '\f3e7';
}
.zmdi-voicemail:before {
  content: '\f3e8';
}
.zmdi-group:before {
  content: '\f3e9';
}
.zmdi-rss:before {
  content: '\f3ea';
}
.zmdi-shape:before {
  content: '\f3eb';
}
.zmdi-spinner:before {
  content: '\f3ec';
}
.zmdi-ungroup:before {
  content: '\f3ed';
}
.zmdi-500px:before {
  content: '\f3ee';
}
.zmdi-8tracks:before {
  content: '\f3ef';
}
.zmdi-amazon:before {
  content: '\f3f0';
}
.zmdi-blogger:before {
  content: '\f3f1';
}
.zmdi-delicious:before {
  content: '\f3f2';
}
.zmdi-disqus:before {
  content: '\f3f3';
}
.zmdi-flattr:before {
  content: '\f3f4';
}
.zmdi-flickr:before {
  content: '\f3f5';
}
.zmdi-github-alt:before {
  content: '\f3f6';
}
.zmdi-google-old:before {
  content: '\f3f7';
}
.zmdi-linkedin:before {
  content: '\f3f8';
}
.zmdi-odnoklassniki:before {
  content: '\f3f9';
}
.zmdi-outlook:before {
  content: '\f3fa';
}
.zmdi-paypal-alt:before {
  content: '\f3fb';
}
.zmdi-pinterest:before {
  content: '\f3fc';
}
.zmdi-playstation:before {
  content: '\f3fd';
}
.zmdi-reddit:before {
  content: '\f3fe';
}
.zmdi-skype:before {
  content: '\f3ff';
}
.zmdi-slideshare:before {
  content: '\f400';
}
.zmdi-soundcloud:before {
  content: '\f401';
}
.zmdi-tumblr:before {
  content: '\f402';
}
.zmdi-twitch:before {
  content: '\f403';
}
.zmdi-vimeo:before {
  content: '\f404';
}
.zmdi-whatsapp:before {
  content: '\f405';
}
.zmdi-xbox:before {
  content: '\f406';
}
.zmdi-yahoo:before {
  content: '\f407';
}
.zmdi-youtube-play:before {
  content: '\f408';
}
.zmdi-youtube:before {
  content: '\f409';
}
.zmdi-import-export:before {
  content: '\f30c';
}
.zmdi-swap-vertical-:before {
  content: '\f30c';
}
.zmdi-airplanemode-inactive:before {
  content: '\f102';
}
.zmdi-airplanemode-active:before {
  content: '\f103';
}
.zmdi-rate-review:before {
  content: '\f103';
}
.zmdi-comment-sign:before {
  content: '\f25a';
}
.zmdi-network-warning:before {
  content: '\f2ad';
}
.zmdi-shopping-cart-add:before {
  content: '\f1ca';
}
.zmdi-file-add:before {
  content: '\f221';
}
.zmdi-network-wifi-scan:before {
  content: '\f2e4';
}
.zmdi-collection-add:before {
  content: '\f14e';
}
.zmdi-format-playlist-add:before {
  content: '\f3ac';
}
.zmdi-format-queue-music:before {
  content: '\f3ab';
}
.zmdi-plus-box:before {
  content: '\f277';
}
.zmdi-tag-backspace:before {
  content: '\f1d9';
}
.zmdi-alarm-add:before {
  content: '\f32b';
}
.zmdi-battery-charging:before {
  content: '\f114';
}
.zmdi-daydream-setting:before {
  content: '\f217';
}
.zmdi-more-horiz:before {
  content: '\f19c';
}
.zmdi-book-photo:before {
  content: '\f11b';
}
.zmdi-incandescent:before {
  content: '\f189';
}
.zmdi-wb-iridescent:before {
  content: '\f38c';
}
.zmdi-calendar-remove:before {
  content: '\f330';
}
.zmdi-refresh-sync-disabled:before {
  content: '\f1b7';
}
.zmdi-refresh-sync-problem:before {
  content: '\f1b6';
}
.zmdi-crop-original:before {
  content: '\f17e';
}
.zmdi-power-off:before {
  content: '\f1af';
}
.zmdi-power-off-setting:before {
  content: '\f1ae';
}
.zmdi-leak-remove:before {
  content: '\f38d';
}
.zmdi-star-border:before {
  content: '\f27c';
}
.zmdi-brightness-low:before {
  content: '\f36d';
}
.zmdi-brightness-medium:before {
  content: '\f36e';
}
.zmdi-brightness-high:before {
  content: '\f36f';
}
.zmdi-smartphone-portrait:before {
  content: '\f2d4';
}
.zmdi-live-tv:before {
  content: '\f2d9';
}
.zmdi-format-textdirection-l-to-r:before {
  content: '\f249';
}
.zmdi-format-textdirection-r-to-l:before {
  content: '\f24a';
}
.zmdi-arrow-back:before {
  content: '\f2ea';
}
.zmdi-arrow-forward:before {
  content: '\f2ee';
}
.zmdi-arrow-in:before {
  content: '\f2e9';
}
.zmdi-arrow-out:before {
  content: '\f2ed';
}
.zmdi-rotate-90-degrees-ccw:before {
  content: '\f304';
}
.zmdi-adb:before {
  content: '\f33a';
}
.zmdi-network-wifi:before {
  content: '\f2e8';
}
.zmdi-network-wifi-alt:before {
  content: '\f2e3';
}
.zmdi-network-wifi-lock:before {
  content: '\f2e5';
}
.zmdi-network-wifi-off:before {
  content: '\f2e6';
}
.zmdi-network-wifi-outline:before {
  content: '\f2e7';
}
.zmdi-network-wifi-info:before {
  content: '\f2e4';
}
.zmdi-layers-clear:before {
  content: '\f18b';
}
.zmdi-colorize:before {
  content: '\f15d';
}
.zmdi-format-paint:before {
  content: '\f1ba';
}
.zmdi-format-quote:before {
  content: '\f1b2';
}
.zmdi-camera-monochrome-photos:before {
  content: '\f285';
}
.zmdi-sort-by-alpha:before {
  content: '\f1cf';
}
.zmdi-folder-shared:before {
  content: '\f225';
}
.zmdi-folder-special:before {
  content: '\f226';
}
.zmdi-comment-dots:before {
  content: '\f260';
}
.zmdi-reorder:before {
  content: '\f31e';
}
.zmdi-dehaze:before {
  content: '\f197';
}
.zmdi-sort:before {
  content: '\f1ce';
}
.zmdi-pages:before {
  content: '\f34a';
}
.zmdi-stack-overflow:before {
  content: '\f35c';
}
.zmdi-calendar-account:before {
  content: '\f204';
}
.zmdi-paste:before {
  content: '\f109';
}
.zmdi-cut:before {
  content: '\f1bc';
}
.zmdi-save:before {
  content: '\f297';
}
.zmdi-smartphone-code:before {
  content: '\f139';
}
.zmdi-directions-bike:before {
  content: '\f117';
}
.zmdi-directions-boat:before {
  content: '\f11a';
}
.zmdi-directions-bus:before {
  content: '\f121';
}
.zmdi-directions-car:before {
  content: '\f125';
}
.zmdi-directions-railway:before {
  content: '\f1b3';
}
.zmdi-directions-run:before {
  content: '\f215';
}
.zmdi-directions-subway:before {
  content: '\f1d5';
}
.zmdi-directions-walk:before {
  content: '\f216';
}
.zmdi-local-hotel:before {
  content: '\f178';
}
.zmdi-local-activity:before {
  content: '\f1df';
}
.zmdi-local-play:before {
  content: '\f1df';
}
.zmdi-local-airport:before {
  content: '\f103';
}
.zmdi-local-atm:before {
  content: '\f198';
}
.zmdi-local-bar:before {
  content: '\f137';
}
.zmdi-local-cafe:before {
  content: '\f13b';
}
.zmdi-local-car-wash:before {
  content: '\f124';
}
.zmdi-local-convenience-store:before {
  content: '\f1d3';
}
.zmdi-local-dining:before {
  content: '\f153';
}
.zmdi-local-drink:before {
  content: '\f157';
}
.zmdi-local-florist:before {
  content: '\f168';
}
.zmdi-local-gas-station:before {
  content: '\f16f';
}
.zmdi-local-grocery-store:before {
  content: '\f1cb';
}
.zmdi-local-hospital:before {
  content: '\f177';
}
.zmdi-local-laundry-service:before {
  content: '\f1e9';
}
.zmdi-local-library:before {
  content: '\f18d';
}
.zmdi-local-mall:before {
  content: '\f195';
}
.zmdi-local-movies:before {
  content: '\f19d';
}
.zmdi-local-offer:before {
  content: '\f187';
}
.zmdi-local-parking:before {
  content: '\f1a5';
}
.zmdi-local-parking:before {
  content: '\f1a5';
}
.zmdi-local-pharmacy:before {
  content: '\f176';
}
.zmdi-local-phone:before {
  content: '\f2be';
}
.zmdi-local-pizza:before {
  content: '\f1ac';
}
.zmdi-local-post-office:before {
  content: '\f15a';
}
.zmdi-local-printshop:before {
  content: '\f1b0';
}
.zmdi-local-see:before {
  content: '\f28c';
}
.zmdi-local-shipping:before {
  content: '\f1e6';
}
.zmdi-local-store:before {
  content: '\f1d4';
}
.zmdi-local-taxi:before {
  content: '\f123';
}
.zmdi-local-wc:before {
  content: '\f211';
}
.zmdi-my-location:before {
  content: '\f299';
}
.zmdi-directions:before {
  content: '\f1e7';
}

.flag {
  display: inline-block;
  background-repeat: no-repeat;
}

.flag.flag-24 {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-image: url(https://xs770303.xsrv.jp/backoffice/static/media/sprite-flags-24x24.7ed12ca4.png);
  background-repeat: no-repeat;
}

.flag.flag-24.flag-abkhazia {
  background-position: -0px -0px;
}

.flag.flag-24.flag-basque-country {
  background-position: -24px -0px;
}

.flag.flag-24.flag-british-antarctic-territory {
  background-position: -48px -0px;
}

.flag.flag-24.flag-commonwealth {
  background-position: -72px -0px;
}

.flag.flag-24.flag-england {
  background-position: -96px -0px;
}

.flag.flag-24.flag-gosquared {
  background-position: -120px -0px;
}

.flag.flag-24.flag-kosovo {
  background-position: -144px -0px;
}

.flag.flag-24.flag-mars {
  background-position: -168px -0px;
}

.flag.flag-24.flag-nagorno-karabakh {
  background-position: -192px -0px;
}

.flag.flag-24.flag-nato {
  background-position: -216px -0px;
}

.flag.flag-24.flag-northern-cyprus {
  background-position: -240px -0px;
}

.flag.flag-24.flag-olympics {
  background-position: -264px -0px;
}

.flag.flag-24.flag-red-cross {
  background-position: -288px -0px;
}

.flag.flag-24.flag-scotland {
  background-position: -312px -0px;
}

.flag.flag-24.flag-somaliland {
  background-position: -336px -0px;
}

.flag.flag-24.flag-south-ossetia {
  background-position: -360px -0px;
}

.flag.flag-24.flag-united-nations {
  background-position: -384px -0px;
}

.flag.flag-24.flag-unknown {
  background-position: -0px -24px;
}

.flag.flag-24.flag-wales {
  background-position: -24px -24px;
}

.flag.flag-24.flag-ad {
  background-position: -48px -24px;
}

.flag.flag-24.flag-ae {
  background-position: -72px -24px;
}

.flag.flag-24.flag-af {
  background-position: -96px -24px;
}

.flag.flag-24.flag-ag {
  background-position: -120px -24px;
}

.flag.flag-24.flag-ai {
  background-position: -144px -24px;
}

.flag.flag-24.flag-al {
  background-position: -168px -24px;
}

.flag.flag-24.flag-am {
  background-position: -192px -24px;
}

.flag.flag-24.flag-an {
  background-position: -216px -24px;
}

.flag.flag-24.flag-ao {
  background-position: -240px -24px;
}

.flag.flag-24.flag-aq {
  background-position: -264px -24px;
}

.flag.flag-24.flag-ar {
  background-position: -288px -24px;
}

.flag.flag-24.flag-as {
  background-position: -312px -24px;
}

.flag.flag-24.flag-at {
  background-position: -336px -24px;
}

.flag.flag-24.flag-au {
  background-position: -360px -24px;
}

.flag.flag-24.flag-aw {
  background-position: -384px -24px;
}

.flag.flag-24.flag-ax {
  background-position: -0px -48px;
}

.flag.flag-24.flag-az {
  background-position: -24px -48px;
}

.flag.flag-24.flag-ba {
  background-position: -48px -48px;
}

.flag.flag-24.flag-bb {
  background-position: -72px -48px;
}

.flag.flag-24.flag-bd {
  background-position: -96px -48px;
}

.flag.flag-24.flag-be {
  background-position: -120px -48px;
}

.flag.flag-24.flag-bf {
  background-position: -144px -48px;
}

.flag.flag-24.flag-bg {
  background-position: -168px -48px;
}

.flag.flag-24.flag-bh {
  background-position: -192px -48px;
}

.flag.flag-24.flag-bi {
  background-position: -216px -48px;
}

.flag.flag-24.flag-bj {
  background-position: -240px -48px;
}

.flag.flag-24.flag-bl {
  background-position: -264px -48px;
}

.flag.flag-24.flag-bm {
  background-position: -288px -48px;
}

.flag.flag-24.flag-bn {
  background-position: -312px -48px;
}

.flag.flag-24.flag-bo {
  background-position: -336px -48px;
}

.flag.flag-24.flag-br {
  background-position: -360px -48px;
}

.flag.flag-24.flag-bs {
  background-position: -384px -48px;
}

.flag.flag-24.flag-bt {
  background-position: -0px -72px;
}

.flag.flag-24.flag-bw {
  background-position: -24px -72px;
}

.flag.flag-24.flag-by {
  background-position: -48px -72px;
}

.flag.flag-24.flag-bz {
  background-position: -72px -72px;
}

.flag.flag-24.flag-ca {
  background-position: -96px -72px;
}

.flag.flag-24.flag-cc {
  background-position: -120px -72px;
}

.flag.flag-24.flag-cd {
  background-position: -144px -72px;
}

.flag.flag-24.flag-cf {
  background-position: -168px -72px;
}

.flag.flag-24.flag-cg {
  background-position: -192px -72px;
}

.flag.flag-24.flag-ch {
  background-position: -216px -72px;
}

.flag.flag-24.flag-ci {
  background-position: -240px -72px;
}

.flag.flag-24.flag-ck {
  background-position: -264px -72px;
}

.flag.flag-24.flag-cl {
  background-position: -288px -72px;
}

.flag.flag-24.flag-cm {
  background-position: -312px -72px;
}

.flag.flag-24.flag-cn {
  background-position: -336px -72px;
}

.flag.flag-24.flag-co {
  background-position: -360px -72px;
}

.flag.flag-24.flag-cr {
  background-position: -384px -72px;
}

.flag.flag-24.flag-cu {
  background-position: -0px -96px;
}

.flag.flag-24.flag-cv {
  background-position: -24px -96px;
}

.flag.flag-24.flag-cw {
  background-position: -48px -96px;
}

.flag.flag-24.flag-cx {
  background-position: -72px -96px;
}

.flag.flag-24.flag-cy {
  background-position: -96px -96px;
}

.flag.flag-24.flag-cz {
  background-position: -120px -96px;
}

.flag.flag-24.flag-de {
  background-position: -144px -96px;
}

.flag.flag-24.flag-dj {
  background-position: -168px -96px;
}

.flag.flag-24.flag-dk {
  background-position: -192px -96px;
}

.flag.flag-24.flag-dm {
  background-position: -216px -96px;
}

.flag.flag-24.flag-do {
  background-position: -240px -96px;
}

.flag.flag-24.flag-dz {
  background-position: -264px -96px;
}

.flag.flag-24.flag-ec {
  background-position: -288px -96px;
}

.flag.flag-24.flag-ee {
  background-position: -312px -96px;
}

.flag.flag-24.flag-eg {
  background-position: -336px -96px;
}

.flag.flag-24.flag-eh {
  background-position: -360px -96px;
}

.flag.flag-24.flag-er {
  background-position: -384px -96px;
}

.flag.flag-24.flag-es {
  background-position: -0px -120px;
}

.flag.flag-24.flag-et {
  background-position: -24px -120px;
}

.flag.flag-24.flag-eu {
  background-position: -48px -120px;
}

.flag.flag-24.flag-fi {
  background-position: -72px -120px;
}

.flag.flag-24.flag-fj {
  background-position: -96px -120px;
}

.flag.flag-24.flag-fk {
  background-position: -120px -120px;
}

.flag.flag-24.flag-fm {
  background-position: -144px -120px;
}

.flag.flag-24.flag-fo {
  background-position: -168px -120px;
}

.flag.flag-24.flag-fr {
  background-position: -192px -120px;
}

.flag.flag-24.flag-ga {
  background-position: -216px -120px;
}

.flag.flag-24.flag-gb {
  background-position: -240px -120px;
}

.flag.flag-24.flag-gd {
  background-position: -264px -120px;
}

.flag.flag-24.flag-ge {
  background-position: -288px -120px;
}

.flag.flag-24.flag-gg {
  background-position: -312px -120px;
}

.flag.flag-24.flag-gh {
  background-position: -336px -120px;
}

.flag.flag-24.flag-gi {
  background-position: -360px -120px;
}

.flag.flag-24.flag-gl {
  background-position: -384px -120px;
}

.flag.flag-24.flag-gm {
  background-position: -0px -144px;
}

.flag.flag-24.flag-gn {
  background-position: -24px -144px;
}

.flag.flag-24.flag-gq {
  background-position: -48px -144px;
}

.flag.flag-24.flag-gr {
  background-position: -72px -144px;
}

.flag.flag-24.flag-gs {
  background-position: -96px -144px;
}

.flag.flag-24.flag-gt {
  background-position: -120px -144px;
}

.flag.flag-24.flag-gu {
  background-position: -144px -144px;
}

.flag.flag-24.flag-gw {
  background-position: -168px -144px;
}

.flag.flag-24.flag-gy {
  background-position: -192px -144px;
}

.flag.flag-24.flag-hk {
  background-position: -216px -144px;
}

.flag.flag-24.flag-hn {
  background-position: -240px -144px;
}

.flag.flag-24.flag-hr {
  background-position: -264px -144px;
}

.flag.flag-24.flag-ht {
  background-position: -288px -144px;
}

.flag.flag-24.flag-hu {
  background-position: -312px -144px;
}

.flag.flag-24.flag-ic {
  background-position: -336px -144px;
}

.flag.flag-24.flag-id {
  background-position: -360px -144px;
}

.flag.flag-24.flag-ie {
  background-position: -384px -144px;
}

.flag.flag-24.flag-il {
  background-position: -0px -168px;
}

.flag.flag-24.flag-im {
  background-position: -24px -168px;
}

.flag.flag-24.flag-in {
  background-position: -48px -168px;
}

.flag.flag-24.flag-iq {
  background-position: -72px -168px;
}

.flag.flag-24.flag-ir {
  background-position: -96px -168px;
}

.flag.flag-24.flag-is {
  background-position: -120px -168px;
}

.flag.flag-24.flag-it {
  background-position: -144px -168px;
}

.flag.flag-24.flag-je {
  background-position: -168px -168px;
}

.flag.flag-24.flag-jm {
  background-position: -192px -168px;
}

.flag.flag-24.flag-jo {
  background-position: -216px -168px;
}

.flag.flag-24.flag-jp {
  background-position: -240px -168px;
}

.flag.flag-24.flag-ke {
  background-position: -264px -168px;
}

.flag.flag-24.flag-kg {
  background-position: -288px -168px;
}

.flag.flag-24.flag-kh {
  background-position: -312px -168px;
}

.flag.flag-24.flag-ki {
  background-position: -336px -168px;
}

.flag.flag-24.flag-km {
  background-position: -360px -168px;
}

.flag.flag-24.flag-kn {
  background-position: -384px -168px;
}

.flag.flag-24.flag-kp {
  background-position: -0px -192px;
}

.flag.flag-24.flag-kr {
  background-position: -24px -192px;
}

.flag.flag-24.flag-kw {
  background-position: -48px -192px;
}

.flag.flag-24.flag-ky {
  background-position: -72px -192px;
}

.flag.flag-24.flag-kz {
  background-position: -96px -192px;
}

.flag.flag-24.flag-la {
  background-position: -120px -192px;
}

.flag.flag-24.flag-lb {
  background-position: -144px -192px;
}

.flag.flag-24.flag-lc {
  background-position: -168px -192px;
}

.flag.flag-24.flag-li {
  background-position: -192px -192px;
}

.flag.flag-24.flag-lk {
  background-position: -216px -192px;
}

.flag.flag-24.flag-lr {
  background-position: -240px -192px;
}

.flag.flag-24.flag-ls {
  background-position: -264px -192px;
}

.flag.flag-24.flag-lt {
  background-position: -288px -192px;
}

.flag.flag-24.flag-lu {
  background-position: -312px -192px;
}

.flag.flag-24.flag-lv {
  background-position: -336px -192px;
}

.flag.flag-24.flag-ly {
  background-position: -360px -192px;
}

.flag.flag-24.flag-ma {
  background-position: -384px -192px;
}

.flag.flag-24.flag-mc {
  background-position: -0px -216px;
}

.flag.flag-24.flag-md {
  background-position: -24px -216px;
}

.flag.flag-24.flag-me {
  background-position: -48px -216px;
}

.flag.flag-24.flag-mf {
  background-position: -72px -216px;
}

.flag.flag-24.flag-mg {
  background-position: -96px -216px;
}

.flag.flag-24.flag-mh {
  background-position: -120px -216px;
}

.flag.flag-24.flag-mk {
  background-position: -144px -216px;
}

.flag.flag-24.flag-ml {
  background-position: -168px -216px;
}

.flag.flag-24.flag-mm {
  background-position: -192px -216px;
}

.flag.flag-24.flag-mn {
  background-position: -216px -216px;
}

.flag.flag-24.flag-mo {
  background-position: -240px -216px;
}

.flag.flag-24.flag-mp {
  background-position: -264px -216px;
}

.flag.flag-24.flag-mq {
  background-position: -288px -216px;
}

.flag.flag-24.flag-mr {
  background-position: -312px -216px;
}

.flag.flag-24.flag-ms {
  background-position: -336px -216px;
}

.flag.flag-24.flag-mt {
  background-position: -360px -216px;
}

.flag.flag-24.flag-mu {
  background-position: -384px -216px;
}

.flag.flag-24.flag-mv {
  background-position: -0px -240px;
}

.flag.flag-24.flag-mw {
  background-position: -24px -240px;
}

.flag.flag-24.flag-mx {
  background-position: -48px -240px;
}

.flag.flag-24.flag-my {
  background-position: -72px -240px;
}

.flag.flag-24.flag-mz {
  background-position: -96px -240px;
}

.flag.flag-24.flag-na {
  background-position: -120px -240px;
}

.flag.flag-24.flag-nc {
  background-position: -144px -240px;
}

.flag.flag-24.flag-ne {
  background-position: -168px -240px;
}

.flag.flag-24.flag-nf {
  background-position: -192px -240px;
}

.flag.flag-24.flag-ng {
  background-position: -216px -240px;
}

.flag.flag-24.flag-ni {
  background-position: -240px -240px;
}

.flag.flag-24.flag-nl {
  background-position: -264px -240px;
}

.flag.flag-24.flag-no {
  background-position: -288px -240px;
}

.flag.flag-24.flag-np {
  background-position: -312px -240px;
}

.flag.flag-24.flag-nr {
  background-position: -336px -240px;
}

.flag.flag-24.flag-nu {
  background-position: -360px -240px;
}

.flag.flag-24.flag-nz {
  background-position: -384px -240px;
}

.flag.flag-24.flag-om {
  background-position: -0px -264px;
}

.flag.flag-24.flag-pa {
  background-position: -24px -264px;
}

.flag.flag-24.flag-pe {
  background-position: -48px -264px;
}

.flag.flag-24.flag-pf {
  background-position: -72px -264px;
}

.flag.flag-24.flag-pg {
  background-position: -96px -264px;
}

.flag.flag-24.flag-ph {
  background-position: -120px -264px;
}

.flag.flag-24.flag-pirate-black {
  background-position: -144px -264px;
}

.flag.flag-24.flag-pirate-white {
  background-position: -168px -264px;
}

.flag.flag-24.flag-pk {
  background-position: -192px -264px;
}

.flag.flag-24.flag-pl {
  background-position: -216px -264px;
}

.flag.flag-24.flag-pn {
  background-position: -240px -264px;
}

.flag.flag-24.flag-pr {
  background-position: -264px -264px;
}

.flag.flag-24.flag-ps {
  background-position: -288px -264px;
}

.flag.flag-24.flag-pt {
  background-position: -312px -264px;
}

.flag.flag-24.flag-pw {
  background-position: -336px -264px;
}

.flag.flag-24.flag-py {
  background-position: -360px -264px;
}

.flag.flag-24.flag-qa {
  background-position: -384px -264px;
}

.flag.flag-24.flag-ro {
  background-position: -0px -288px;
}

.flag.flag-24.flag-rs {
  background-position: -24px -288px;
}

.flag.flag-24.flag-ru {
  background-position: -48px -288px;
}

.flag.flag-24.flag-rw {
  background-position: -72px -288px;
}

.flag.flag-24.flag-sa {
  background-position: -96px -288px;
}

.flag.flag-24.flag-sb {
  background-position: -120px -288px;
}

.flag.flag-24.flag-sc {
  background-position: -144px -288px;
}

.flag.flag-24.flag-sd {
  background-position: -168px -288px;
}

.flag.flag-24.flag-se {
  background-position: -192px -288px;
}

.flag.flag-24.flag-sg {
  background-position: -216px -288px;
}

.flag.flag-24.flag-sh {
  background-position: -240px -288px;
}

.flag.flag-24.flag-si {
  background-position: -264px -288px;
}

.flag.flag-24.flag-sk {
  background-position: -288px -288px;
}

.flag.flag-24.flag-sl {
  background-position: -312px -288px;
}

.flag.flag-24.flag-sm {
  background-position: -336px -288px;
}

.flag.flag-24.flag-sn {
  background-position: -360px -288px;
}

.flag.flag-24.flag-so {
  background-position: -384px -288px;
}

.flag.flag-24.flag-sr {
  background-position: -0px -312px;
}

.flag.flag-24.flag-ss {
  background-position: -24px -312px;
}

.flag.flag-24.flag-st {
  background-position: -48px -312px;
}

.flag.flag-24.flag-sv {
  background-position: -72px -312px;
}

.flag.flag-24.flag-sy {
  background-position: -96px -312px;
}

.flag.flag-24.flag-sz {
  background-position: -120px -312px;
}

.flag.flag-24.flag-tc {
  background-position: -144px -312px;
}

.flag.flag-24.flag-td {
  background-position: -168px -312px;
}

.flag.flag-24.flag-tf {
  background-position: -192px -312px;
}

.flag.flag-24.flag-tg {
  background-position: -216px -312px;
}

.flag.flag-24.flag-th {
  background-position: -240px -312px;
}

.flag.flag-24.flag-tj {
  background-position: -264px -312px;
}

.flag.flag-24.flag-tk {
  background-position: -288px -312px;
}

.flag.flag-24.flag-tl {
  background-position: -312px -312px;
}

.flag.flag-24.flag-tm {
  background-position: -336px -312px;
}

.flag.flag-24.flag-tn {
  background-position: -360px -312px;
}

.flag.flag-24.flag-to {
  background-position: -384px -312px;
}

.flag.flag-24.flag-tr {
  background-position: -0px -336px;
}

.flag.flag-24.flag-tt {
  background-position: -24px -336px;
}

.flag.flag-24.flag-tv {
  background-position: -48px -336px;
}

.flag.flag-24.flag-tw {
  background-position: -72px -336px;
}

.flag.flag-24.flag-tz {
  background-position: -96px -336px;
}

.flag.flag-24.flag-ua {
  background-position: -120px -336px;
}

.flag.flag-24.flag-ug {
  background-position: -144px -336px;
}

.flag.flag-24.flag-us {
  background-position: -168px -336px;
}

.flag.flag-24.flag-uy {
  background-position: -192px -336px;
}

.flag.flag-24.flag-uz {
  background-position: -216px -336px;
}

.flag.flag-24.flag-va {
  background-position: -240px -336px;
}

.flag.flag-24.flag-vc {
  background-position: -264px -336px;
}

.flag.flag-24.flag-ve {
  background-position: -288px -336px;
}

.flag.flag-24.flag-vg {
  background-position: -312px -336px;
}

.flag.flag-24.flag-vi {
  background-position: -336px -336px;
}

.flag.flag-24.flag-vn {
  background-position: -360px -336px;
}

.flag.flag-24.flag-vu {
  background-position: -384px -336px;
}





* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.card {
  background-color: var(--white);
  padding: 1.25rem;
  border-radius: 15px;
}

.tabs .MuiTabs-scroller {
  border-bottom: 1px solid var(--gray);
  height: -webkit-max-content;
  height: max-content;
}

.tabs .MuiTab-root {
  min-width: 150px;
  min-height: 40px;
}

.tabs .MuiTabs-indicator {
  background-color: var(--primary);
}

button.MuiButton-root.MuiPickersToolbarButton-toolbarBtn {
  height: -webkit-max-content;
  height: max-content;
}

h2.MuiPickersTimePickerToolbar-separator {
  margin-bottom: 0.25rem;
}

.MuiDialogContent-root {
  border-width: 2px !important;
}

.MuiDialogTitle-root {
  font-size: 1.35rem;
}

.ql-editor {
  min-height: 230px;
}

img {
  object-fit: contain;
}

:is(button, label).MuiButton-root {
  border-radius: 0.5rem;
  font-size: 0.9rem;
  height: 40px;
  min-width: unset;
}

.btn-white {
  color: var(--primary) !important;
  background-color: var(--white) !important;
  border: 1px solid var(--gray) !important;
}

.btn-primary {
  color: var(--white) !important;
  background-color: var(--primary) !important;
}

.btn-gray-black {
  background-color: var(--gray-black) !important;
  color: var(--white) !important;
}

.btn-blue {
  background-color: var(--blue) !important;
  color: var(--white) !important;
}

.btn-orange-2 {
  background-color: var(--orange-2) !important;
  color: var(--white) !important;
}

.btn-success {
  background-color: #49bd65 !important;
  color: var(--white) !important;
}

.input-search .MuiInput-formControl::before,
.input-search .MuiInput-formControl::after,
.input-container .MuiInput-formControl::before,
.input-container .MuiInput-formControl::after {
  display: none;
}

.input-search .MuiInput-formControl {
  border: 1px solid var(--gray) !important;
  background-color: var(--white);
  border-radius: 0.25rem;
  padding: 0px 5px;
}

.input-container {
  transition: all linear 0.2s;
}

.input-container label {
  color: var(--black) !important;
  transform: unset;
  position: unset;
  font-size: 0.9rem;
  font-weight: 500;
}

.input-container .MuiInput-formControl {
  background: var(--white);
  border: 2px solid var(--gray);
  border-radius: 10px;
  height: auto;
}

.input-container:has(label) .MuiInput-formControl {
  margin-top: 0.5rem;
}

.input-container .MuiInput-formControl:has(:is(.Mui-disabled, input:disabled)) {
  background-color: rgba(23, 25, 28, 0.1);
}

.input-container
  .MuiInput-formControl:has(:is(.Mui-disabled, input:disabled))
  input {
  max-width: 100%;
}

.input-container :is(input, textarea) {
  font-size: 0.9rem;
  font-weight: 400;
  padding: 0.75rem !important;
  resize: both;
  color: var(--black);
}

.input-container .MuiFormLabel-asterisk.MuiInputLabel-asterisk {
  color: var(--secondary);
}

.input-container .MuiInputBase-root.MuiAutocomplete-inputRoot {
  padding-right: 0 !important;
}
.input-container .MuiInput-formControl .MuiAutocomplete-endAdornment {
  position: static;
}

input[type='checkbox'] {
  width: 20px;
  height: 20px;
}

.input-select-no_fly_zone_method
  :is(.MuiChip-deleteIcon, .MuiAutocomplete-endAdornment) {
  display: none;
}

.input-select-no_fly_zone_method input {
  max-width: 0px !important;
  padding-left: 0.01px !important;
  padding-right: 0px !important;
  min-width: unset !important;
}

.input-select-no_fly_zone_method .MuiAutocomplete-inputRoot.Mui-disabled {
  background-color: var(--white) !important;
}

.input-select-no_fly_zone_method .MuiChip-root.Mui-disabled {
  opacity: 1;
}

.input-select-no_fly_zone_method .MuiInputBase-root.Mui-disabled {
  cursor: pointer;
}

.input-container .MuiInputBase-multiline {
  padding: 0;
}

.table-container .MuiTableCell-head {
  min-width: -webkit-max-content;
  min-width: max-content;
  font-size: 0.9rem;
  background-color: #213983;
  color: var(--white);
  font-weight: 600;
}

.table-container .MuiTableCell-body {
  font-size: 0.9rem !important;
}

.table-container .MuiTableBody-root .MuiTableRow-root:nth-child(even) {
  background-color: #f8f9fa;
}

.table-output_log {
  border: 1px solid #dddddd;
}

.table-output_log table {
  table-layout: auto;
  width: 100%;
  border-collapse: collapse;
}

.table-output_log table *:is(th, td) {
  border: 1px solid #dddddd;
  padding: 0.5rem 1rem;
  text-align: center;
}

.table-output_log table th {
  font-weight: 600;
}

.table-output_log::-webkit-scrollbar,
.table-output_log::-webkit-scrollbar-thumb {
  height: 9px;
  width: 9px;
  border: 2px solid transparent;
  background-color: var(--white);
}

.table-output_log::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 9px;
  color: #aaa;
  border-radius: 9px;
}

.table-container :is(.MuiTableCell-body, .MuiTableCell-head),
.table-output_log :is(th, td) {
  white-space: nowrap;
}

.table-form :is(.MuiTableCell-body, .MuiTableCell-head) {
  border: 1.5px solid var(--primary);
}

.table-form .MuiTableCell-body {
  padding: 0;
  background-color: var(--white);
}

.table-form .input-container .MuiInput-formControl {
  border-radius: 0;
  border: unset;
}

.img-pilot {
  width: 10rem;
  height: 10rem;
  object-fit: contain;
  object-position: center;
}

.img-uav {
  width: 12em;
  height: 12em !important;
  margin-bottom: 0.25em;
  object-fit: contain;
}

.list-notification {
  list-style: none;
}

.list-notification .notification-items {
  border-bottom: 1px solid var(--gray-black);
}

.dashboard-company_owner {
  margin: -20px -20px 0;
}

.dashboard-pilot {
  max-height: calc(100svh - 70px);
  overflow: auto;
}

@media (min-width: 960px) {
  .dashboard-company_owner {
    margin: -30px -32px;
  }

  .dashboard-pilot {
    display: flex;
    flex-direction: column;
  }

  .dashboard-pilot .dashboard-content {
    max-height: 100%;
    overflow: auto;
  }
}

.dashboard-pilot .notification {
  max-height: 7.52rem;
  overflow: auto;
  direction: rtl;
  border-bottom: 1px solid black;
  padding-left: 2px;
}

.dashboard-pilot .notification .item-notification {
  border-bottom: 1px solid #000000;
  direction: ltr;
}

.dashboard-pilot .notification .item-notification:last-child {
  border-bottom: none;
}

.dashboard-pilot .notification .title {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  direction: ltr;
  border-bottom: 1px solid #757272;
  z-index: 1;
  color: #757272;
  font-size: 1.1rem;
}

.dashboard-pilot
  :is(
    .notification-company,
    .notification-company::-webkit-scrollbar-track,
    .notification-company .title
  ) {
  background-color: #f8b62d;
}

.dashboard-pilot
  :is(
    .notification-admin,
    .notification-admin::-webkit-scrollbar-track,
    .notification-admin .title
  ) {
  background-color: #ffe200;
}

:is(
    .dashboard-pilot,
    .dashboard-content,
    .dashboard-pilot .notification
  )::-webkit-scrollbar {
  width: 8px;
}

:is(
    .dashboard-pilot,
    .dashboard-content,
    .dashboard-pilot .notification
  )::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 3px;
}

:is(
    .dashboard-pilot,
    .dashboard-content,
    .dashboard-pilot .notification
  )::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.flex {
  display: flex !important;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.d-block {
  display: block !important;
}

.d-inline-block {
  display: inline-block !important;
}
.d-none {
  display: none !important;
}
.d-inline-flex {
  display: inline-flex !important;
}

.items-center {
  align-items: center !important;
}

.items-start {
  align-items: flex-start !important;
}

.items-end {
  align-items: flex-end !important;
}

.justify-center {
  justify-content: center !important;
}

.justify-start {
  justify-content: flex-start !important;
}

.justify-end {
  justify-content: flex-end !important;
}

.justify-between {
  justify-content: space-between !important;
}

.justify-around {
  justify-content: space-around !important;
}

.justify-evenly {
  justify-content: space-evenly !important;
}

.direction-column {
  flex-direction: column;
}

@media (min-width: 600px) {
  .d-sm-none {
    display: none !important;
  }
}

@media (min-width: 1280px) {
  .d-lg-block {
    display: block !important;
  }
}

h1 {
  font-size: 2.25rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

.text-4 {
  font-size: 1rem;
}

.text-5 {
  font-size: 1.25rem;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.whitespace-pre-wrap {
  white-space: pre-wrap;
}

.text-18px {
  font-size: 18px;
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.m-0,
.mx-0,
.mr-0 {
  margin-right: 0rem !important;
}

.m-0,
.mx-0,
.ml-0 {
  margin-left: 0rem !important;
}

.m-0,
.my-0,
.mt-0 {
  margin-top: 0rem !important;
}

.m-0,
.my-0,
.mb-0 {
  margin-bottom: 0rem !important;
}

.p-0,
.px-0,
.pr-0 {
  padding-right: 0rem !important;
}

.p-0,
.px-0,
.pl-0 {
  padding-left: 0rem !important;
}

.p-0,
.py-0,
.pt-0 {
  padding-top: 0rem !important;
}

.p-0,
.py-0,
.pb-0 {
  padding-bottom: 0rem !important;
}

.m-1,
.mx-1,
.mr-1 {
  margin-right: 0.25rem !important;
}

.m-1,
.mx-1,
.ml-1 {
  margin-left: 0.25rem !important;
}

.m-1,
.my-1,
.mt-1 {
  margin-top: 0.25rem !important;
}

.m-1,
.my-1,
.mb-1 {
  margin-bottom: 0.25rem !important;
}

.p-1,
.px-1,
.pr-1 {
  padding-right: 0.25rem !important;
}

.p-1,
.px-1,
.pl-1 {
  padding-left: 0.25rem !important;
}

.p-1,
.py-1,
.pt-1 {
  padding-top: 0.25rem !important;
}

.p-1,
.py-1,
.pb-1 {
  padding-bottom: 0.25rem !important;
}

.m-2,
.mx-2,
.mr-2 {
  margin-right: 0.5rem !important;
}

.m-2,
.mx-2,
.ml-2 {
  margin-left: 0.5rem !important;
}

.m-2,
.my-2,
.mt-2 {
  margin-top: 0.5rem !important;
}

.m-2,
.my-2,
.mb-2 {
  margin-bottom: 0.5rem !important;
}

.p-2,
.px-2,
.pr-2 {
  padding-right: 0.5rem !important;
}

.p-2,
.px-2,
.pl-2 {
  padding-left: 0.5rem !important;
}

.p-2,
.py-2,
.pt-2 {
  padding-top: 0.5rem !important;
}

.p-2,
.py-2,
.pb-2 {
  padding-bottom: 0.5rem !important;
}

.m-3,
.mx-3,
.mr-3 {
  margin-right: 0.75rem !important;
}

.m-3,
.mx-3,
.ml-3 {
  margin-left: 0.75rem !important;
}

.m-3,
.my-3,
.mt-3 {
  margin-top: 0.75rem !important;
}

.m-3,
.my-3,
.mb-3 {
  margin-bottom: 0.75rem !important;
}

.p-3,
.px-3,
.pr-3 {
  padding-right: 0.75rem !important;
}

.p-3,
.px-3,
.pl-3 {
  padding-left: 0.75rem !important;
}

.p-3,
.py-3,
.pt-3 {
  padding-top: 0.75rem !important;
}

.p-3,
.py-3,
.pb-3 {
  padding-bottom: 0.75rem !important;
}

.m-4,
.mx-4,
.mr-4 {
  margin-right: 1rem !important;
}

.m-4,
.mx-4,
.ml-4 {
  margin-left: 1rem !important;
}

.m-4,
.my-4,
.mt-4 {
  margin-top: 1rem !important;
}

.m-4,
.my-4,
.mb-4 {
  margin-bottom: 1rem !important;
}

.p-4,
.px-4,
.pr-4 {
  padding-right: 1rem !important;
}

.p-4,
.px-4,
.pl-4 {
  padding-left: 1rem !important;
}

.p-4,
.py-4,
.pt-4 {
  padding-top: 1rem !important;
}

.p-4,
.py-4,
.pb-4 {
  padding-bottom: 1rem !important;
}

.m-5,
.mx-5,
.mr-5 {
  margin-right: 1.25rem !important;
}

.m-5,
.mx-5,
.ml-5 {
  margin-left: 1.25rem !important;
}

.m-5,
.my-5,
.mt-5 {
  margin-top: 1.25rem !important;
}

.m-5,
.my-5,
.mb-5 {
  margin-bottom: 1.25rem !important;
}

.p-5,
.px-5,
.pr-5 {
  padding-right: 1.25rem !important;
}

.p-5,
.px-5,
.pl-5 {
  padding-left: 1.25rem !important;
}

.p-5,
.py-5,
.pt-5 {
  padding-top: 1.25rem !important;
}

.p-5,
.py-5,
.pb-5 {
  padding-bottom: 1.25rem !important;
}

.m-6,
.mx-6,
.mr-6 {
  margin-right: 1.5rem !important;
}

.m-6,
.mx-6,
.ml-6 {
  margin-left: 1.5rem !important;
}

.m-6,
.my-6,
.mt-6 {
  margin-top: 1.5rem !important;
}

.m-6,
.my-6,
.mb-6 {
  margin-bottom: 1.5rem !important;
}

.p-6,
.px-6,
.pr-6 {
  padding-right: 1.5rem !important;
}

.p-6,
.px-6,
.pl-6 {
  padding-left: 1.5rem !important;
}

.p-6,
.py-6,
.pt-6 {
  padding-top: 1.5rem !important;
}

.p-6,
.py-6,
.pb-6 {
  padding-bottom: 1.5rem !important;
}

.m-7,
.mx-7,
.mr-7 {
  margin-right: 1.75rem !important;
}

.m-7,
.mx-7,
.ml-7 {
  margin-left: 1.75rem !important;
}

.m-7,
.my-7,
.mt-7 {
  margin-top: 1.75rem !important;
}

.m-7,
.my-7,
.mb-7 {
  margin-bottom: 1.75rem !important;
}

.p-7,
.px-7,
.pr-7 {
  padding-right: 1.75rem !important;
}

.p-7,
.px-7,
.pl-7 {
  padding-left: 1.75rem !important;
}

.p-7,
.py-7,
.pt-7 {
  padding-top: 1.75rem !important;
}

.p-7,
.py-7,
.pb-7 {
  padding-bottom: 1.75rem !important;
}

.m-8,
.mx-8,
.mr-8 {
  margin-right: 2rem !important;
}

.m-8,
.mx-8,
.ml-8 {
  margin-left: 2rem !important;
}

.m-8,
.my-8,
.mt-8 {
  margin-top: 2rem !important;
}

.m-8,
.my-8,
.mb-8 {
  margin-bottom: 2rem !important;
}

.p-8,
.px-8,
.pr-8 {
  padding-right: 2rem !important;
}

.p-8,
.px-8,
.pl-8 {
  padding-left: 2rem !important;
}

.p-8,
.py-8,
.pt-8 {
  padding-top: 2rem !important;
}

.p-8,
.py-8,
.pb-8 {
  padding-bottom: 2rem !important;
}

.m-9,
.mx-9,
.mr-9 {
  margin-right: 2.25rem !important;
}

.m-9,
.mx-9,
.ml-9 {
  margin-left: 2.25rem !important;
}

.m-9,
.my-9,
.mt-9 {
  margin-top: 2.25rem !important;
}

.m-9,
.my-9,
.mb-9 {
  margin-bottom: 2.25rem !important;
}

.p-9,
.px-9,
.pr-9 {
  padding-right: 2.25rem !important;
}

.p-9,
.px-9,
.pl-9 {
  padding-left: 2.25rem !important;
}

.p-9,
.py-9,
.pt-9 {
  padding-top: 2.25rem !important;
}

.p-9,
.py-9,
.pb-9 {
  padding-bottom: 2.25rem !important;
}

.m-10,
.mx-10,
.mr-10 {
  margin-right: 2.5rem !important;
}

.m-10,
.mx-10,
.ml-10 {
  margin-left: 2.5rem !important;
}

.m-10,
.my-10,
.mt-10 {
  margin-top: 2.5rem !important;
}

.m-10,
.my-10,
.mb-10 {
  margin-bottom: 2.5rem !important;
}

.p-10,
.px-10,
.pr-10 {
  padding-right: 2.5rem !important;
}

.p-10,
.px-10,
.pl-10 {
  padding-left: 2.5rem !important;
}

.p-10,
.py-10,
.pt-10 {
  padding-top: 2.5rem !important;
}

.p-10,
.py-10,
.pb-10 {
  padding-bottom: 2.5rem !important;
}

.m-11,
.mx-11,
.mr-11 {
  margin-right: 2.75rem !important;
}

.m-11,
.mx-11,
.ml-11 {
  margin-left: 2.75rem !important;
}

.m-11,
.my-11,
.mt-11 {
  margin-top: 2.75rem !important;
}

.m-11,
.my-11,
.mb-11 {
  margin-bottom: 2.75rem !important;
}

.p-11,
.px-11,
.pr-11 {
  padding-right: 2.75rem !important;
}

.p-11,
.px-11,
.pl-11 {
  padding-left: 2.75rem !important;
}

.p-11,
.py-11,
.pt-11 {
  padding-top: 2.75rem !important;
}

.p-11,
.py-11,
.pb-11 {
  padding-bottom: 2.75rem !important;
}

.m-12,
.mx-12,
.mr-12 {
  margin-right: 3rem !important;
}

.m-12,
.mx-12,
.ml-12 {
  margin-left: 3rem !important;
}

.m-12,
.my-12,
.mt-12 {
  margin-top: 3rem !important;
}

.m-12,
.my-12,
.mb-12 {
  margin-bottom: 3rem !important;
}

.p-12,
.px-12,
.pr-12 {
  padding-right: 3rem !important;
}

.p-12,
.px-12,
.pl-12 {
  padding-left: 3rem !important;
}

.p-12,
.py-12,
.pt-12 {
  padding-top: 3rem !important;
}

.p-12,
.py-12,
.pb-12 {
  padding-bottom: 3rem !important;
}

.m-13,
.mx-13,
.mr-13 {
  margin-right: 3.25rem !important;
}

.m-13,
.mx-13,
.ml-13 {
  margin-left: 3.25rem !important;
}

.m-13,
.my-13,
.mt-13 {
  margin-top: 3.25rem !important;
}

.m-13,
.my-13,
.mb-13 {
  margin-bottom: 3.25rem !important;
}

.p-13,
.px-13,
.pr-13 {
  padding-right: 3.25rem !important;
}

.p-13,
.px-13,
.pl-13 {
  padding-left: 3.25rem !important;
}

.p-13,
.py-13,
.pt-13 {
  padding-top: 3.25rem !important;
}

.p-13,
.py-13,
.pb-13 {
  padding-bottom: 3.25rem !important;
}

.m-14,
.mx-14,
.mr-14 {
  margin-right: 3.5rem !important;
}

.m-14,
.mx-14,
.ml-14 {
  margin-left: 3.5rem !important;
}

.m-14,
.my-14,
.mt-14 {
  margin-top: 3.5rem !important;
}

.m-14,
.my-14,
.mb-14 {
  margin-bottom: 3.5rem !important;
}

.p-14,
.px-14,
.pr-14 {
  padding-right: 3.5rem !important;
}

.p-14,
.px-14,
.pl-14 {
  padding-left: 3.5rem !important;
}

.p-14,
.py-14,
.pt-14 {
  padding-top: 3.5rem !important;
}

.p-14,
.py-14,
.pb-14 {
  padding-bottom: 3.5rem !important;
}

.m-15,
.mx-15,
.mr-15 {
  margin-right: 3.75rem !important;
}

.m-15,
.mx-15,
.ml-15 {
  margin-left: 3.75rem !important;
}

.m-15,
.my-15,
.mt-15 {
  margin-top: 3.75rem !important;
}

.m-15,
.my-15,
.mb-15 {
  margin-bottom: 3.75rem !important;
}

.p-15,
.px-15,
.pr-15 {
  padding-right: 3.75rem !important;
}

.p-15,
.px-15,
.pl-15 {
  padding-left: 3.75rem !important;
}

.p-15,
.py-15,
.pt-15 {
  padding-top: 3.75rem !important;
}

.p-15,
.py-15,
.pb-15 {
  padding-bottom: 3.75rem !important;
}

.m-16,
.mx-16,
.mr-16 {
  margin-right: 4rem !important;
}

.m-16,
.mx-16,
.ml-16 {
  margin-left: 4rem !important;
}

.m-16,
.my-16,
.mt-16 {
  margin-top: 4rem !important;
}

.m-16,
.my-16,
.mb-16 {
  margin-bottom: 4rem !important;
}

.p-16,
.px-16,
.pr-16 {
  padding-right: 4rem !important;
}

.p-16,
.px-16,
.pl-16 {
  padding-left: 4rem !important;
}

.p-16,
.py-16,
.pt-16 {
  padding-top: 4rem !important;
}

.p-16,
.py-16,
.pb-16 {
  padding-bottom: 4rem !important;
}

.m-17,
.mx-17,
.mr-17 {
  margin-right: 4.25rem !important;
}

.m-17,
.mx-17,
.ml-17 {
  margin-left: 4.25rem !important;
}

.m-17,
.my-17,
.mt-17 {
  margin-top: 4.25rem !important;
}

.m-17,
.my-17,
.mb-17 {
  margin-bottom: 4.25rem !important;
}

.p-17,
.px-17,
.pr-17 {
  padding-right: 4.25rem !important;
}

.p-17,
.px-17,
.pl-17 {
  padding-left: 4.25rem !important;
}

.p-17,
.py-17,
.pt-17 {
  padding-top: 4.25rem !important;
}

.p-17,
.py-17,
.pb-17 {
  padding-bottom: 4.25rem !important;
}

.m-18,
.mx-18,
.mr-18 {
  margin-right: 4.5rem !important;
}

.m-18,
.mx-18,
.ml-18 {
  margin-left: 4.5rem !important;
}

.m-18,
.my-18,
.mt-18 {
  margin-top: 4.5rem !important;
}

.m-18,
.my-18,
.mb-18 {
  margin-bottom: 4.5rem !important;
}

.p-18,
.px-18,
.pr-18 {
  padding-right: 4.5rem !important;
}

.p-18,
.px-18,
.pl-18 {
  padding-left: 4.5rem !important;
}

.p-18,
.py-18,
.pt-18 {
  padding-top: 4.5rem !important;
}

.p-18,
.py-18,
.pb-18 {
  padding-bottom: 4.5rem !important;
}

.m-19,
.mx-19,
.mr-19 {
  margin-right: 4.75rem !important;
}

.m-19,
.mx-19,
.ml-19 {
  margin-left: 4.75rem !important;
}

.m-19,
.my-19,
.mt-19 {
  margin-top: 4.75rem !important;
}

.m-19,
.my-19,
.mb-19 {
  margin-bottom: 4.75rem !important;
}

.p-19,
.px-19,
.pr-19 {
  padding-right: 4.75rem !important;
}

.p-19,
.px-19,
.pl-19 {
  padding-left: 4.75rem !important;
}

.p-19,
.py-19,
.pt-19 {
  padding-top: 4.75rem !important;
}

.p-19,
.py-19,
.pb-19 {
  padding-bottom: 4.75rem !important;
}

.m-20,
.mx-20,
.mr-20 {
  margin-right: 5rem !important;
}

.m-20,
.mx-20,
.ml-20 {
  margin-left: 5rem !important;
}

.m-20,
.my-20,
.mt-20 {
  margin-top: 5rem !important;
}

.m-20,
.my-20,
.mb-20 {
  margin-bottom: 5rem !important;
}

.p-20,
.px-20,
.pr-20 {
  padding-right: 5rem !important;
}

.p-20,
.px-20,
.pl-20 {
  padding-left: 5rem !important;
}

.p-20,
.py-20,
.pt-20 {
  padding-top: 5rem !important;
}

.p-20,
.py-20,
.pb-20 {
  padding-bottom: 5rem !important;
}

.m-auto,
.mx-auto,
.mr-auto {
  margin-right: auto !important;
}

.m-auto,
.mx-auto,
.ml-auto {
  margin-left: auto !important;
}

.m-auto,
.my-auto,
.mt-auto {
  margin-top: auto !important;
}

.m-auto,
.my-auto,
.mb-auto {
  margin-bottom: auto !important;
}

.gap-1 {
  grid-gap: 0.25rem !important;
  gap: 0.25rem !important;
}

.gap-2 {
  grid-gap: 0.5rem !important;
  gap: 0.5rem !important;
}

.bg-primary {
  background-color: var(--primary);
  color: var(--white);
}

.bg-green {
  background-color: var(--green);
  color: var(--white);
}

.bg-orange {
  background-color: var(--orange);
  color: var(--white);
}

.bg-secondary {
  background-color: var(--secondary);
  color: var(--white);
}

.bg-gray {
  background-color: var(--gray);
  color: var(--primary);
}

.bg-blue {
  background-color: var(--blue);
  color: var(--white);
}

.bg-white {
  background-color: var(--white);
  color: var(--black);
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-disabled {
  background-color: rgba(23, 25, 28, 0.1) !important;
}
.text-white {
  color: var(--white);
}

.text-black {
  color: var(--black);
}

.text-primary {
  color: var(--primary);
}

.text-gray-black {
  color: var(--gray-black);
}

.text-secondary {
  color: var(--secondary);
}

.rounded-1 {
  border-radius: 0.25rem;
}

.rounded-2 {
  border-radius: 0.5rem !important;
}

.rounded-full {
  border-radius: 100%;
}

.outline-none {
  outline: none !important;
}

.border-gray {
  border: 1px solid var(--gray);
}

.border-none {
  border: none !important;
}

.w-full {
  width: 100% !important;
}

.h-full {
  height: 100%;
}

.h-auto {
  height: auto !important;
}

.min-w-max {
  min-width: -webkit-max-content !important;
  min-width: max-content !important;
}

.min-w-none {
  min-width: unset !important;
}

.h-max {
  height: -webkit-max-content;
  height: max-content;
}

.min-h-full {
  min-height: 100% !important;
}

.w-max {
  width: -webkit-max-content;
  width: max-content;
}

.max-w-full {
  max-width: 100%;
}

.cursor-pointer {
    cursor: pointer;
}
.overflow-auto {
  overflow: auto;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.shadow {
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
}

:root {
  --white: #ffffff;
  --gray: #cbd2dc80;
  --gray-black: #9898a3;
  --primary: #213983;
  --blue: #4fb6cf;
  --green: #449e3c;
  --orange: #ed7d2b;
  --orange-2: #fe7951;
  --secondary: #db0d4b;
  --black: #000000;
  --xs: 0px;
  --sm: 600px;
  --md: 960px;
  --lg: 1280px;
  --xl: 1920px;
  --height-notification-dashboard: 90px;
}


.dashboard-company_owner {
  margin: -20px -20px 0;
}

.dashboard-pilot {
  max-height: calc(100svh - 70px);
  overflow: auto;
}

@media (min-width: 960px) {
  .dashboard-company_owner {
    margin: -30px -32px;
  }

  .dashboard-pilot {
    display: flex;
    flex-direction: column;
  }

  .dashboard-pilot .dashboard-content {
    max-height: 100%;
    overflow: auto;
  }
}

.dashboard-pilot .notification {
  max-height: 7.52rem;
  overflow: auto;
  direction: rtl;
  border-bottom: 1px solid black;
  padding-left: 2px;
}

.dashboard-pilot .notification .item-notification {
  border-bottom: 1px solid #000000;
  direction: ltr;
}

.dashboard-pilot .notification .item-notification:last-child {
  border-bottom: none;
}

.dashboard-pilot .notification .title {
  position: sticky;
  top: 0;
  left: 0;
  direction: ltr;
  border-bottom: 1px solid #757272;
  z-index: 1;
  color: #757272;
  font-size: 1.1rem;
}

.dashboard-pilot
  :is(
    .notification-company,
    .notification-company::-webkit-scrollbar-track,
    .notification-company .title
  ) {
  background-color: #f8b62d;
}

.dashboard-pilot
  :is(
    .notification-admin,
    .notification-admin::-webkit-scrollbar-track,
    .notification-admin .title
  ) {
  background-color: #ffe200;
}

:is(
    .dashboard-pilot,
    .dashboard-content,
    .dashboard-pilot .notification
  )::-webkit-scrollbar {
  width: 8px;
}

:is(
    .dashboard-pilot,
    .dashboard-content,
    .dashboard-pilot .notification
  )::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 3px;
}

:is(
    .dashboard-pilot,
    .dashboard-content,
    .dashboard-pilot .notification
  )::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
